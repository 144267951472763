import React from 'react';
import { MDBPagination, MDBPaginationItem, MDBPaginationLink } from 'mdb-react-ui-kit';
import './Pagination.css';

const Pagination = ({ offsetPage, setOffsetPage, totalPages, lastPage }) => {

  const pageNumbers = Array.from({ length: 5 }, (_, index) => offsetPage - 1 + index)
    .filter(num => num >= 0 && num < totalPages);

  if (pageNumbers[0] > 0) {
    pageNumbers.unshift(0);
  }
  if (pageNumbers[pageNumbers.length - 1] < totalPages - 1) {
    pageNumbers.push(totalPages - 1);
  }
  

  return (
    <nav>
      {
        !lastPage ? 
        <MDBPagination className='mb-0 d-flex justify-content-end'>
          <MDBPaginationItem>
            <MDBPaginationLink onClick={() => { setOffsetPage(Math.max(1, offsetPage - 1)) }}>Previous</MDBPaginationLink>
          </MDBPaginationItem>
          {pageNumbers.map(pageNumber => (
            <MDBPaginationItem key={pageNumber} active={pageNumber === offsetPage}>
              <MDBPaginationLink onClick={() => { setOffsetPage(pageNumber) }}>
                {pageNumber + 1}
              </MDBPaginationLink>
            </MDBPaginationItem>
          ))}
          <MDBPaginationItem>
            <MDBPaginationLink onClick={() => { setOffsetPage(Math.min(totalPages, offsetPage + 1)) }}>Next</MDBPaginationLink>
          </MDBPaginationItem>
        </MDBPagination>
        : 
        <MDBPagination className='mb-0 d-flex justify-content-end'>
          <MDBPaginationItem>
            <MDBPaginationLink onClick={() => { setOffsetPage(Math.max(1, offsetPage - 1)) }}>No more pages Available</MDBPaginationLink>
          </MDBPaginationItem>
        </MDBPagination>
      }
    </nav>
  );
};

export default Pagination;
