import React, { useEffect, useState } from 'react';
import { MDBCheckbox, MDBSideNavCollapse, MDBSideNavItem, MDBSideNavLink } from 'mdb-react-ui-kit';
import axios from 'axios';

const BillType = ({ selectedBillTypes, setSelectedBillTypes, setOffset, billTypesCount, sessionId }) => {
    const [collapse, setCollapse] = useState(false);
    const [billTypes, setBillTypes] = useState([]);

    useEffect(() => {
        const fetchData = async () => {
            try {
                let archiveBillTypes = [];
                let lawsBillTypes = [];
                
                const archiveResponse = await axios.get(`${process.env.REACT_APP_LAWS_ARCHIVE_BACKEND_HOST}/v1/billTypes`);
                const lawsResponse = sessionId > 20231 ? await axios.get(`${process.env.REACT_APP_LAWS_BACKEND_HOST}/bills/v1/billTypes`) : null;
                
                if (archiveResponse.data) {
                    archiveBillTypes = archiveResponse.data.map(billType => ({
                        typeCode: billType.typeCode,
                        description: billType.description
                    }));
                }

                if (lawsResponse && lawsResponse.data) {
                    lawsBillTypes = lawsResponse.data.map(billType => ({
                        typeCode: billType.billTypeCode,
                        description: billType.billTypeDescription
                    }));
                }

                const mergedBillTypes = mergeBillTypes(archiveBillTypes, lawsBillTypes);
                setBillTypes(mergedBillTypes);
            } catch (error) {
                console.error('Error fetching data: ', error);
            }
        };
        fetchData();
    }, [billTypesCount, sessionId]);

    const mergeBillTypes = (archiveBillTypes, lawsBillTypes) => {
        const mergedMap = new Map();

        archiveBillTypes.forEach(billType => mergedMap.set(billType.typeCode, billType));
        lawsBillTypes.forEach(billType => mergedMap.set(billType.typeCode, billType));

        return Array.from(mergedMap.values());
    };

    const handleCheckboxChange = (value) => {
        const isSelected = selectedBillTypes.includes(value);
        setSelectedBillTypes(isSelected ? selectedBillTypes.filter((type) => type !== value) : [...selectedBillTypes, value]);
        setOffset(0);
    };

    return (
        <MDBSideNavItem>
            <MDBSideNavLink className='accordion-button' icon='angle-down' shouldBeExpanded={collapse} onClick={() => setCollapse(!collapse)}>
                Bill Type
            </MDBSideNavLink>
            <MDBSideNavCollapse show={collapse}>
                <li className='p-3'>
                    {
                        billTypes.map((billType, index) => (
                            <div key={index} className="d-flex justify-content-between align-items-start">
                                <MDBCheckbox
                                    name='billTypeCode'
                                    value={billType.typeCode}
                                    id={`billTypeCodeId-${index}`}
                                    label={`${billType.description} (${billType.typeCode})`}
                                    className="float-start legmt-checkbox"
                                    checked={selectedBillTypes.includes(billType.typeCode)}
                                    onChange={() => handleCheckboxChange(billType.typeCode)}
                                />
                                <div>
                                    {/* <span className="badge text-bg-light">{billTypesCount ? billTypesCount[billType.typeCode] || 0 : 0}</span> */}
                                </div>
                            </div>
                        ))
                    }
                    <div className="d-flex justify-content-between align-items-start">
                        <MDBCheckbox
                            name='billTypeCode'
                            value=""
                            id='billTypeCodeId-unintroduced'
                            label={'Unintroduced Bills'}
                            className="float-start legmt-checkbox"
                            checked={selectedBillTypes.includes("")}
                            onChange={() => handleCheckboxChange("")}
                        />
                        <div>
                            {/* <span className="badge text-bg-light">{billTypesCount ? billTypesCount.NoBillType || 0 : 0}</span> */}
                        </div>
                    </div>
                </li>
            </MDBSideNavCollapse>
        </MDBSideNavItem>
    );
};

export default BillType;
