import axios from 'axios';
import { MDBDatepicker, MDBSelect, MDBSideNavCollapse, MDBSideNavItem, MDBSideNavLink, MDBIcon, MDBPopover, MDBPopoverHeader, MDBPopoverBody } from 'mdb-react-ui-kit';
import React, { useEffect, useState } from 'react';

const Status = ({ selectedActionTypes, setSelectedActionTypes, setOffset, setStatusStartDate, setStatusEndDate, statusStartDate, statusEndDate }) => {
    const [collapse, setCollapse] = useState(false);
    const [status, setStatus] = useState([]);
    const [selectedOption, setSelectedOption] = useState("");
    const [localStartDate, setLocalStartDate] = useState(null);
    const [localEndDate, setLocalEndDate] = useState(null);

    useEffect(() => {
        const fetchData = async () => {
            try {
                const { data } = await axios.get(`${process.env.REACT_APP_LAWS_ARCHIVE_BACKEND_HOST}/v1/actionTypes`);
                const uniqueDescriptions = new Set();
                const options = data.reduce((acc, item) => {
                    const normalizedText = item.description.replace(/^(?:\(H\)\s|\(C\)\s)/, '');
                    if (!uniqueDescriptions.has(normalizedText)) {
                        uniqueDescriptions.add(normalizedText);
                        acc.push({
                            value: item.code,
                            text: item.description,
                            normalizedText: normalizedText
                        });
                    }
                    return acc;
                }, [{ value: "0", text: "-- Select Status --", disabled: true, normalizedText: "" }]);

                options.sort((a, b) => a.normalizedText.localeCompare(b.normalizedText));
                setStatus(options);
            } catch (error) {
                console.log({ error });
            }
        };
        fetchData();
    }, []);

    useEffect(() => {
        if (selectedActionTypes.length === 0) {
            setSelectedOption("0");
        }
    }, [selectedActionTypes]);

    useEffect(() => {
        if (!statusStartDate) setLocalStartDate("");
        if (!statusEndDate) setLocalEndDate("");
    }, [statusStartDate, statusEndDate]);

    const handleStatusSelect = (value) => {
        if (value === "0" || !value) {
            setSelectedOption("0");
            setSelectedActionTypes([]);
        } else {
            setSelectedOption(value);
            setSelectedActionTypes([value]);
        }
        setOffset(0);
    };

    return (
        <MDBSideNavItem>
            <MDBSideNavLink className='accordion-button' icon='angle-down' shouldBeExpanded={collapse} onClick={() => setCollapse(!collapse)}>
                Status
            </MDBSideNavLink>
            <MDBSideNavCollapse show={collapse}>
                <li className='p-3'>
                    <div className='d-flex justify-content-end'>
                        <MDBPopover className="" html='true' tag='a' href='#' btnChildren={<MDBIcon fas icon="info-circle" />} dismiss placement='top'>
                            <MDBPopoverHeader>Status Search</MDBPopoverHeader>
                            <MDBPopoverBody><p>When you select a status, but do not put in any dates, you will receive any bills or LCs that have that status listed as the last and final status.</p>
                                <h5>Status + Dates:</h5>
                                <ul>
                                    <li><strong>Status + Only Start Date:</strong> This will return bills that have the selected status on a date after the selected start date.</li>
                                    <li><strong>Status + Only End Date:</strong> This will return bills that have the selected status on a date before the selected end date.</li>
                                    <li><strong>Status + Both Start & End Dates:</strong> This will return bills that have the selected status within the selected period (start-end).</li>
                                </ul>
                            </MDBPopoverBody>
                        </MDBPopover>
                    </div>
                    <MDBSelect
                        data={status}
                        id="actionFilter"
                        value={selectedOption}
                        clearBtn
                        onValueChange={({ value }) => handleStatusSelect(value)}
                        search
                    />
                    <div className='mt-3'>
                        <div className="input-daterange input-group" id="datepicker">
                            <MDBDatepicker value={localStartDate} id="startDateDatePicker" onChange={(value) => {setLocalStartDate(value); setStatusStartDate(value)}} inline format='mm/dd/yyyy' inputToggle label="Date start" clearBtnText='RESET' />
                            <span className="input-group-text">to</span>
                            <MDBDatepicker value={localEndDate} id="endDateDatePicker" onChange={(value) => {setLocalEndDate(value); setStatusEndDate(value)}} inline format='mm/dd/yyyy' inputToggle label="Date end" clearBtnText='RESET' />
                        </div>
                    </div>
                </li>
            </MDBSideNavCollapse>
        </MDBSideNavItem>
    )
}

export default Status;
