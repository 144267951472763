import {
    MDBDatatable,
    MDBDropdown,
    MDBDropdownItem,
    MDBDropdownMenu,
    MDBDropdownToggle,
    MDBTabsPane
} from 'mdb-react-ui-kit';
import React, {useEffect, useState} from 'react';
import {format, parseISO} from 'date-fns';
import {CSVLink} from 'react-csv';
import {dateHelpers} from '../../../helper/dates';
import jsPDF from 'jspdf';
import 'jspdf-autotable';
import axios from "axios";

const convertISOToReadableTimeFormat = (date) => {
    return format(parseISO(date), "MM/dd/yy");
};

const formatDate = (dateString) => {
    const date = new Date(dateString);
    const year = date.getFullYear();
    const month = String(date.getMonth() + 1).padStart(2, '0');
    const day = String(date.getDate()).padStart(2, '0');
    return `${year}-${month}-${day}`;
};

const tableHeading = [
    {label: 'Date', field: 'Date'},
    {label: 'Action', field: 'Action'}
];

const generateMediaUI = (media) => {
    if (!Array.isArray(media) || media.length < 2) {
        return null;
    }
    const baseUrl = 'http://sg001-harmony.sliq.net/00309/Harmony/en/PowerBrowser/PowerBrowserV2/20170221/-1/';
    const audioUrl = `${baseUrl}${media[0].harmonyClipId}?agendaId=${media[0].harmonyMarkerId}`;
    const videoUrl = `${baseUrl}${media[1].harmonyClipId}?agendaId=${media[1].harmonyMarkerId}`;

    return (
        <div className='text-center'>
            <a target="_blank" href={audioUrl} className="mx-2" aria-label="Audio">
                <i className="fa-solid fa-volume-low" aria-hidden="true"></i>
            </a>
            <a target="_blank" href={videoUrl} className="mx-2" aria-label="Video">
                <i className="fa-solid fa-film" aria-hidden="true"></i>
            </a>
        </div>
    );
}

const formatRowStructureForExport = (billActions) => {
    return billActions.map((item) => {
        return {
            "Date": convertISOToReadableTimeFormat(item?.date),
            "Action": item?.actionType?.description ? item.actionType.description.replace("Print Version Ready", "") : ''
        };
    }).filter(item => item.Action.startsWith("(C)"));
};

const formatRowStructureForStatusDatatable = ({floorVoteSheetLinks, voteSheetLinks, billActions}) => {
    return billActions.reduce((formattedRows, item) => {
        const actionDescription = item?.actionType?.description || '';
        const filteredDescription = actionDescription.replace("Print Version Ready", "").trim();
        if (filteredDescription.startsWith("(C)")) {
            formattedRows.push({
                "Date": convertISOToReadableTimeFormat(item?.date),
                "Action": filteredDescription
            });
        }
        return formattedRows;
    }, []);
};

const exportData = (data, fileName, format) => {
    if (format === 'csv') {
        return (
            <CSVLink className='csv-link' id="csvLink" data={data} filename={`${fileName}.csv`}>Excel/CSV</CSVLink>
        );
    } else if (format === 'pdf') {
        const doc = new jsPDF();
        doc.text('Exported Data', 10, 10);
        const headers = Object.keys(data[0]);
        const tableData = data.map(row => headers.map(header => row[header]));
        doc.autoTable({head: [headers], body: tableData});
        doc.save(`${fileName}.pdf`);
        return null;
    } else if (format === 'print') {
        window.print();
    } else {
        console.error('Unsupported export format');
        return null;
    }
};

const isSameDate = (date1, date2) => {
    if (!(date1 instanceof Date) || !(date2 instanceof Date)) {
        return false;
    }
    return (
        date1.getFullYear() === date2.getFullYear() &&
        date1.getMonth() === date2.getMonth() &&
        date1.getDate() === date2.getDate()
    );
};

const extractDateFromFilename = (filename) => {
    const datePattern = /\d{6}/;
    const match = filename.match(datePattern);

    if (match) {
        const dateString = match[0];
        const year = parseInt(dateString.slice(0, 2), 10) + 2000;
        const month = parseInt(dateString.slice(2, 4), 10) - 1;
        const day = parseInt(dateString.slice(4, 6), 10);

        return new Date(year, month, day);
    }

    return null;
}

const handleVoteSheetVotesLink = (documentId) => {
    async function fetchDataAndGotoSheetLinkUrl(documentId) {
        try {
            const {data} = await axios.post(`${process.env.REACT_APP_DOCS_BACKEND_HOST}/v1/documents/shortPdfUrl?documentId=${documentId}`);
            const pdfUrl = data;
            window.open(pdfUrl, '_blank');
        } catch (error) {
            console.error("Error fetching vote link data: ", error);
            throw error;
        }
    }

    fetchDataAndGotoSheetLinkUrl(documentId);
}


const hasVoteSheetVotesLink = (voteSheetLinks, floorVoteSheetLinks, voteSeq, statusItemDate, voteCount) => {
    const createLink = (id) => (
        <a
            href="#"
            onClick={(e) => {
                e.preventDefault();
                handleVoteSheetVotesLink(id);
            }}
        >
            {voteCount}
        </a>
    );

    // Check for floor vote sheets
    const floorVoteSeq = voteSeq ? (voteSeq.length > 1 ? voteSeq.slice(1) : '') : null;
    if (floorVoteSeq) {
        const matchingFloorVoteSheetLink = floorVoteSheetLinks.find((voteDoc) => {
            // Extract the relevant part from fileName and compare it with floorVoteSeq
            const fileNameParts = voteDoc.fileName.split('.');
            const relevantPart = fileNameParts.length > 1 ? fileNameParts[1].split('.')[0] : '';
            return relevantPart === floorVoteSeq;
        });

        if (matchingFloorVoteSheetLink) {
            //console.log('Matching floor vote sheet found:', matchingFloorVoteSheetLink);
            return createLink(matchingFloorVoteSheetLink.id);
        }
    }

    // If no floor vote sheet found, check regular vote sheets
    if (voteCount === null) {
        return " - ";
    }

    const itemDate = new Date(statusItemDate);

    const matchingVoteSheetLink = voteSheetLinks.find((vote) => {
        const voteSheetDateExtracted = extractDateFromFilename(vote.fileName);
        return isSameDate(itemDate, voteSheetDateExtracted);
    });

    if (matchingVoteSheetLink) {
        //console.log('Matching regular vote sheet found:', matchingVoteSheetLink.id);
        return createLink(matchingVoteSheetLink.id);
    } else {
        //console.log('No matching regular vote sheet found');
        return voteCount;
    }
}

const Status = ({active, bill, ordinal}) => {
    const [formattedData, setFormattedData] = useState({columns: tableHeading, rows: []});
    const [originalData, setOriginalData] = useState([]);
    const [tableClick, setTableClick] = useState(false);
    const [billVoteSheetLinks, setBillVoteSheetLinks] = useState([]);
    const [billFloorVoteSheetLinks, setBillFloorVoteSheetLinks] = useState([]);

    useEffect(() => {
        async function fetchDataAndSetVoteSheetLinks() {
            try {
                if (!bill.sessionLawChapterNumber || !bill.id.billDraftNumber || !bill.billType || !bill.billNumber) throw new Error("bill details not available");
                const {data} = await axios.get(`${process.env.REACT_APP_DOCS_BACKEND_HOST}/v1/documents/getBillVoteSheets?legislatureOrdinal=${ordinal}&sessionOrdinal=${bill.id.sessionId}&billType=${bill.billType.trim()}&billNumber=${bill.billNumber}`);
                setBillVoteSheetLinks(data);
            } catch (error) {
                console.error('Error fetching other bill vote sheets: ', error);
            }
        }

        fetchDataAndSetVoteSheetLinks();
    }, [bill.id.sessionId, bill.id.billDraftNumber, bill.billType, bill.billNumber]);
    useEffect(() => {
        async function fetchDataAndSetFloorVoteSheetLinks() {
            try {
                if (!bill.id.sessionId || !bill.billType || !bill.billNumber) throw new Error("bill details not available");
                const requestBody = JSON.stringify([
                    {
                        "field": "Session",
                        "composition": "and",
                        "operator": "equals",
                        "stringValue": Math.floor(bill.id.sessionId / 10),
                        "extendedAttribute":1,
                        "type": 0
                    },{
                        "field": `${process.env.REACT_APP_FLOORVOTE_LOOKUP_KEY}`,
                        "composition": "and",
                        "operator": "contains",
                        "stringValue": bill.billType+" "+bill.billNumber,
                        "extendedAttribute":1,
                        "type": 0
                    }
                ]);
                const { data } = await axios.post(
                    `${process.env.REACT_APP_DOCS_BACKEND_HOST}/v1/documents/findByParameters?templateId=${process.env.REACT_APP_FLOORVOTE_TEMPLATE}`,
                    requestBody,
                    {
                        headers: {
                            'Content-Type': 'application/json',
                            'Accept': '*/*'
                        }
                    }
                );
                setBillFloorVoteSheetLinks(data);

            } catch (error) {
                console.error('Error fetching other bill floor vote sheets: ', error);
            }
        }

        fetchDataAndSetFloorVoteSheetLinks();
    }, [bill.id.sessionId, bill.billType, bill.billNumber]);

    useEffect(() => {
        async function fetchBillMediaLinks() {
            try {
                if (!bill.id.sessionId || !bill.sessionLawChapterNumber || !bill.id.billDraftNumber || !bill.billType || !bill.billNumber) {
                    throw new Error("Bill details not available");
                }
                const {data} = await axios.get(`${process.env.REACT_APP_MEDIA_ARCHIVE_BACKEND_HOST}/v1/mediaLinks?sessionId=${bill.id.sessionId}&billDraftNumber=${bill.id.billDraftNumber}&billType=${bill.billType.trim()}&billNumber=${bill.billNumber}`);
                return data;
            } catch (error) {
                console.error("Error fetching data: ", error);
                return null;
            }
        }

        async function processBillActions(mediaLinks) {
            const includedCodes = [112, 113, 156, 157, 159, 161, 172, 210, 220, 512, 513, 556, 557, 558, 572, 610, 612, 619];
            const excludedCodes = [116, 117, 153, 516, 553];
            const processedActions = bill.billActions.map(billAction => {
                if (mediaLinks) {
                    const matches = mediaLinks.filter(item => {
                        return formatDate(item.videoDate) === formatDate(billAction.date);
                    });
                    if (matches.length > 0) {
                        const filteredMatches = billAction.actionType.code &&
                        includedCodes.includes(billAction.actionType.code) &&
                        !excludedCodes.includes(billAction.actionType.code) ? matches : [];
                        return {...billAction, media: filteredMatches.length > 0 ? filteredMatches : false};
                    }
                    return {...billAction, media: false};
                } else {
                    return {...billAction, media: false};
                }
            });

            const formattedRow = formatRowStructureForStatusDatatable({
                floorVoteSheetLinks: billFloorVoteSheetLinks,
                voteSheetLinks: billVoteSheetLinks,
                billActions: processedActions
            });
            setFormattedData({columns: tableHeading, rows: formattedRow});
            setOriginalData(formattedRow);
        }

        async function fetchDataAndProcess() {
            const mediaLinks = await fetchBillMediaLinks();
            processBillActions(mediaLinks);
        }

        fetchDataAndProcess();
    }, [billVoteSheetLinks, billFloorVoteSheetLinks, bill.id.sessionId, bill.id.billDraftNumber, bill.billType, bill.billNumber]);

    const handleCsvClick = () => {
        const link = document.getElementById('csvLink');
        link.click();
    };

    return (
        <MDBTabsPane show={active}>
            <div className="accordion-body">
                <div className='row py-3 g-0'>
                    <div className='col-sm align-items-center text-center text-md-start mb-3 mb-md-0'>
                        <div className="form-check form-check-inline">
                        </div>
                    </div>
                    <div className='col-sm align-items-center'>
                        {/* <input
                            style={{ width: '100%' }}
                            className='form-control'
                            placeholder='Search Status...'
                            type="text"
                            value={searchTerm}
                            onChange={(e) => setSearchTerm(e.target.value)}
                        /> */}
                    </div>
                    <div className='col-auto mt-sm-0 mx-auto mt-3'>
                        <div className='d-flex justify-content-end p-0'>
                            <MDBDropdown>
                                <MDBDropdownToggle className='shadow-none ms-1'><i
                                    className="fa-light fa-cloud-arrow-down"
                                    aria-hidden="true"></i> Export</MDBDropdownToggle>
                                <MDBDropdownMenu>
                                    <MDBDropdownItem link onClick={(e) => {
                                        e.preventDefault();
                                        handleCsvClick()
                                    }}>{exportData(formattedData.rows, `Status_details_${dateHelpers.getCurrentDate()}`, 'csv')}</MDBDropdownItem>
                                    <MDBDropdownItem link onClick={(e) => {
                                        e.preventDefault();
                                        exportData(formatRowStructureForExport(bill.billActions), `Status_details_${dateHelpers.getCurrentDate()}`, 'pdf')
                                    }}>PDF/Print</MDBDropdownItem>
                                </MDBDropdownMenu>
                            </MDBDropdown>
                        </div>
                    </div>
                </div>
                <div id="table-actions_wrapper" className="dataTables_wrapper dt-bootstrap5 no-footer">
                    <div className='row'>
                        <MDBDatatable
                            data={formattedData}
                            fixedHeader={true}
                            bordered
                            pagination={false}
                            entries={1000}
                            className='status-datatable'
                            onClick={() => {
                                setTableClick(!tableClick)
                            }}
                        />
                    </div>
                    <div className="row">
                        <div className="col-md-5">
                            <div className="dataTables_info" id="table-actions_info" role="status" aria-live="polite">
                                <small>Showing 1
                                    to {formattedData.rows.length} of {formattedData.rows.length} entries</small></div>
                        </div>
                        <div className="col-md-7">
                            <div className="dataTables_paginate paging_simple_numbers" id="table-actions_paginate">
                                <ul className="pagination mt-3">
                                    <li className="paginate_button page-item previous disabled"
                                        id="table-actions_previous">
                                        <a aria-controls="table-actions" aria-disabled="true" role="link"
                                           data-dt-idx="previous" tabIndex="0" className="page-link">Previous</a>
                                    </li>
                                    <li className="paginate_button page-item active">
                                        <a href="#" aria-controls="table-actions" role="link" aria-current="page"
                                           data-dt-idx="0" tabIndex="0" className="page-link">1</a>
                                    </li>
                                    <li className="paginate_button page-item next disabled" id="table-actions_next">
                                        <a aria-controls="table-actions" aria-disabled="true" role="link"
                                           data-dt-idx="next" tabIndex="0" className="page-link">Next</a>
                                    </li>
                                </ul>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </MDBTabsPane>
    );
};

export default Status;


