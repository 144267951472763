import React, { useEffect, useState } from 'react';
import {
    MDBTabs,
    MDBTabsItem,
    MDBTabsLink,
    MDBTabsContent,
    MDBIcon
} from 'mdb-react-ui-kit';
import Summary from "./Tabs/Summary";
import BillText from './Tabs/BillText';
import DocumentsAndLinks from './Tabs/DocumentsAndLinks';
import Fiscal from './Tabs/Fiscal';
import Amendments from './Tabs/Amendments';
import Status from './Tabs/Status';


const tabMapping = {
    'Summary': 'sum',
    'LcText': 'lctext',
    'LcStatus': 'lcstatus',
};

const Tabs = ({billDetails, ordinal, defaultTab}) => {
    const [basicActive, setBasicActive] = useState('Summary');

    const handleBasicClick = (value) => {
        if (value === basicActive) {
            return;
        }
        setBasicActive(value);
    };

    useEffect(() => {
        const mappedTab = Object.keys(tabMapping).find(key => tabMapping[key] === defaultTab.toLowerCase());
        if (mappedTab) setBasicActive(mappedTab);
    }, [defaultTab]);

    return (
        <div>
            <MDBTabs>
                <MDBTabsItem>
                    <MDBTabsLink onClick={() => handleBasicClick('Summary')} active={basicActive === 'Summary'} >
                        <MDBIcon fas icon="clipboard-list" /> Summary
                    </MDBTabsLink>
                </MDBTabsItem>
                <MDBTabsItem>
                    <MDBTabsLink onClick={() => handleBasicClick('LcText')} active={basicActive === 'LcText'}>
                        <MDBIcon far icon="file-alt" /> LC Text
                    </MDBTabsLink>
                </MDBTabsItem>
                <MDBTabsItem>
                    <MDBTabsLink onClick={() => handleBasicClick('LcStatus')} active={basicActive === 'LcStatus'}>
                        <MDBIcon far icon="check-circle" /> LC Status
                    </MDBTabsLink>
                </MDBTabsItem>
            </MDBTabs>

            <MDBTabsContent className='bg-white p-3'>
                <Summary active={ basicActive === 'Summary'} bill={billDetails} onClickOpenTab={handleBasicClick} ordinal={ordinal}/>
                <BillText active={ basicActive === 'LcText'} bill={billDetails} ordinal={ordinal}/>
                <Status active={ basicActive === 'LcStatus'} bill={billDetails} ordinal={ordinal}/>
            </MDBTabsContent>
        </div>
    );
};

export default Tabs;