import React, {useEffect, useState} from 'react';
import { HashRouter as Router, Routes, Route } from 'react-router-dom';
import Home from "./Components/Home/Home";
import BillDetails from "./Components/BillDetails/BillDetails";
import axios from "axios";
import LCBillDetails from './Components/LCBillDetails/BillDetails';


function App() {
    const [serverStatus, setServerStatus] = useState('loading');

    useEffect(() => {
        axios.get(`${process.env.REACT_APP_LAWS_ARCHIVE_BACKEND_HOST}/v1/sessions`)
            .then(response => {
                if (response.data!=null) {
                    setServerStatus("success");
                }else{
                    setServerStatus("error");
                }
            })
            .catch(error => {
                console.error('Server is down', error);
                setServerStatus("error");
            });
    }, []);

    if (serverStatus === 'loading') {
        return <div>Loading...</div>; // or a spinner component
    }

    if (serverStatus === 'error') {
        return <div>Server is currently unavailable. make sure that backend is UP . Please try again later.</div>;
    }
    return (
        <Router>
            <Routes>
                <Route path="/" element={<Home />} />
                <Route path="/bill/:sessionId/:id" element={<BillDetails />} />
                <Route path="/bill/:sessionId/byBill/:id" element={<BillDetails />} />
                <Route path="/lc/bill/:sessionId/:id" element={<LCBillDetails />} />
            </Routes>
        </Router>
    );
}

export default App;
