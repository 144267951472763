import axios from 'axios';
import { MDBCheckbox, MDBSideNavCollapse, MDBSideNavItem, MDBSideNavLink, MDBSpinner } from 'mdb-react-ui-kit';
import React, { useEffect, useState } from 'react'

const Chamber = ({selectedChambers, setSelectedChambers, setOffset, chambersCount}) => {

    const [collapse, setCollapse] = useState(false);
    const [chambers, setChambers] = useState([]);
    const [isLoading, setIsLoading] = useState(true);

    useEffect(() => {
        const fetchData = async () => {
            try {
                const { data } = await axios.get(`${process.env.REACT_APP_LAWS_ARCHIVE_BACKEND_HOST}/v1/chambers`);
                setChambers(data);
                setIsLoading(false);
            } catch (error) {
                console.error('Error fetching data: ', error);
            }
        };
        setIsLoading(true);
        fetchData();
    }, [chambersCount]);

    const handleCheckboxChange = (value) => {
        const isSelected = selectedChambers.includes(value);
        if (isSelected) {
            setSelectedChambers(selectedChambers.filter((type) => type !== value));
        } else {
          setSelectedChambers([...selectedChambers, value]);
        }
        setOffset(0);
    };

    return (
        <MDBSideNavItem>
            <MDBSideNavLink className='accordion-button' icon='angle-down' shouldBeExpanded={collapse} onClick={() => setCollapse(!collapse)}>
                Chamber
            </MDBSideNavLink>
            <MDBSideNavCollapse show={collapse}>
                <li className='p-3'>
                    {   isLoading ? <span className='mx-4'><MDBSpinner size='sm' role='status' tag='span' className='me-2' /> Loading...</span> :
                        chambers.map((chamber, index) => {
                            return <div key={index} className="d-flex justify-content-between align-items-start">
                                <MDBCheckbox 
                                    name='houseChamber' 
                                    value={chamber.houseCode}
                                    label={chamber.houseName} 
                                    className="float-start legmt-checkbox"
                                    checked={selectedChambers.includes(chamber.houseCode)}
                                    onChange={() => handleCheckboxChange(chamber.houseCode)}
                                />
                                {/* <div>
                                     <span className="badge text-bg-light">{chambersCount ? chambersCount[chamber.houseCode] ? chambersCount[chamber.houseCode] : 0 : 0}</span>
                                </div> */}
                            </div>
                        })
                    }
                </li>
            </MDBSideNavCollapse>
        </MDBSideNavItem>
    )
}

export default Chamber