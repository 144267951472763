import React, { useEffect, useState } from 'react';
import { MDBSelect, MDBSideNavCollapse, MDBSideNavItem, MDBSideNavLink, MDBSpinner } from 'mdb-react-ui-kit';
import axios from 'axios';


const Progress = ({ sessionId, setSelectedProgress, setOffset, selectedProgress }) => {
    const [collapse, setCollapse] = useState(false);
    const [progress, setProgress] = useState([]);
    const [selectedOption, setSelectedOption] = useState("");
    const [isLoading, setIsLoading] = useState(true);


    useEffect(() => {
        const fetchData = async () => {
            setIsLoading(true);


            try {
                let response;
                let mappedProgress = [];


                if (sessionId > 20231) {
                    response = await axios.get(`${process.env.REACT_APP_LAWS_BACKEND_HOST}/bills/v1/billProgressCategories`);
                    mappedProgress = response.data.map(item => ({
                        value: item.code,
                        text: item.description
                    }));
                } else {
                    response = await axios.get(`${process.env.REACT_APP_LAWS_ARCHIVE_BACKEND_HOST}/v1/progressCategories`);
                    mappedProgress = response.data.map(item => ({
                        value: item.code,
                        text: item.description
                    }));
                }


                const options = [{ value: "0", text: "-- Select Progress --", disabled: true }, ...mappedProgress];
                setProgress(options);
                setIsLoading(false);
            } catch (error) {
                console.error('Error fetching data: ', error);
                setIsLoading(false);
            }
        };


        fetchData();
    }, [sessionId]);


    useEffect(() => {
        if (!selectedProgress) {
            setSelectedOption("0");
        }
    }, [selectedProgress]);


    const handleProgressSelect = (value) => {
        if (value === "0") {
            setSelectedOption(value);
            setSelectedProgress(sessionId > 20231 ? [] : "");
        } else {
            setSelectedOption(value);

            if (sessionId > 20231) {
                setSelectedProgress([value]);
            } else {
                setSelectedProgress(value);
            }
        }
        setOffset(0);
    };


    return (
        <MDBSideNavItem>
            <MDBSideNavLink className='accordion-button' icon='angle-down' shouldBeExpanded={collapse} onClick={() => setCollapse(!collapse)}>
                Progress
            </MDBSideNavLink>
            <MDBSideNavCollapse show={collapse}>
                <li className='p-3'>
                    {isLoading ? (
                        <span className='mx-4'>
                            <MDBSpinner size='sm' role='status' tag='span' className='me-2' />
                            Loading...
                        </span>
                    ) : (
                        <MDBSelect
                            data={progress}
                            id="progressFilter"
                            search
                            clearBtn
                            value={selectedOption}
                            onValueChange={({ value }) => handleProgressSelect(value)}
                        />
                    )}
                </li>
            </MDBSideNavCollapse>
        </MDBSideNavItem>
    );
};


export default Progress;





