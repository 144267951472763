import { MDBSelect, MDBSideNavCollapse, MDBSideNavItem, MDBSideNavLink, MDBSwitch } from 'mdb-react-ui-kit'
import React, { useEffect, useState } from 'react'
import Deadlines from './Deadline';
import Drafters from './Drafters';


const OtherFilters = ({sessionId, selectedDrafter, setSelectedDrafter, setOffset, draftersCount, setHasAmendment, setHasFiscalNotes,
                        deadlineCategoriesCount, selectedDeadlines, setSelectedDeadlines, hasAmendment, hasFiscalNotes, hasLegalNotes, 
                        setHasLegalNotes, selectedProgress }) => {


    const [collapse, setCollapse] = useState(false);
    const [hasAmendmentState, setHasAmendmentState] = useState(false);
    const [hasFiscalNotesState, setHasFiscalNotesState] = useState(false);
    const [hasLegalNotesState, setHasLegalNotesState] = useState(false);


    useEffect( () => {
        setHasAmendmentState(hasAmendment); 
    }, [hasAmendment]);

    useEffect( () => {
        setHasFiscalNotesState(hasFiscalNotes);
    }, [hasFiscalNotes]);

    useEffect( () => {
        setHasLegalNotesState(hasLegalNotes);
    }, [hasLegalNotes]);
   
    return (
        <MDBSideNavItem>
            <MDBSideNavLink className='accordion-button' icon='angle-down' shouldBeExpanded={collapse} onClick={() => setCollapse(!collapse)}>
               Other
            </MDBSideNavLink>
            <MDBSideNavCollapse show={collapse}>
                <div className='p-3'>
                    <div className="mb-1 d-flex align-items-center">
                        <MDBSwitch checked={hasAmendmentState} id='flexSwitchCheckChecked' onChange={(event) => { setHasAmendment(event.target.checked) }} label='Has Amendment(s)' />
                    </div>
                    <div className="mb-1 d-flex align-items-center">
                        <MDBSwitch checked={hasFiscalNotesState} id='fiscal' onChange={(event) => { setHasFiscalNotes(event.target.checked) }} label='Has Fiscal Note(s)' />
                    </div>
                    <div className="mb-1 d-flex align-items-center">
                        <MDBSwitch checked={hasLegalNotesState} id='legal' onChange={(event) => { setHasLegalNotes(event.target.checked) }} label='Has Legal Note(s)' />
                    </div>
                    <hr/>
                    <Drafters sessionId={sessionId} selectedDrafter={selectedDrafter} setSelectedDrafter={setSelectedDrafter} setOffset={setOffset} draftersCount={draftersCount} />
                    <hr />
                    <Deadlines sessionId={sessionId} deadlineCategoriesCount={deadlineCategoriesCount} selectedDeadlines={selectedDeadlines} setSelectedDeadlines={setSelectedDeadlines}/>
                </div>
            </MDBSideNavCollapse>
        </MDBSideNavItem>
    )
}


export default OtherFilters;


