import React, { useState, useEffect, useRef } from 'react';
import { MDBContainer } from 'mdb-react-ui-kit';
import BillsTable from "./BillsTable/BillsTable";
import PreHeader from "./PreHeader";
import Filter from './Filter/Filter';
import AppBuildVersion from "../AppBuildVersion/AppBuildVersion";

function Home() {

    const [container, setContainer] = useState();
    const sidenavContent = useRef(null);
    const [sideNavToggle, setSideNavToggle] = useState(false);
    const [sessionId, setSessionId ] = useState(20231);
    const [sessionInfoForHeader, setSessionInfoForHeader] = useState("Loading...");
    const [legislatureId, setLegislatureId] = useState(1);
    const [offset, setOffset] = useState(0);
    const [subjectCode, setSubjectCode] = useState([]);
    const [selectedBillTypes, setSelectedBillTypes] = useState([]);
    const [selectedLegislator, setSelectedLegislator] = useState("");
    const [selectedDrafter, setSelectedDrafter] = useState("");
    const [selectedParties, setSelectedParties] = useState([]);
    const [selectedChambers, setSelectedChambers] = useState([]);
    const [selectedCommittee, setSelectedCommitte] = useState("");
    const [selectedProgress, setSelectedProgress] = useState("");
    const [hasAmendment, setHasAmendment] = useState(false);
    const [hasFiscalNotes, setHasFiscalNotes] = useState(false);
    const [hasLegalNotes, setHasLegalNotes] = useState(false);
    const [selectedDeadlines, setSelectedDeadlines] = useState([]);
    const [selectedActionTypes, setSelectedActionTypes]  =  useState([]);
    const [statusStartDate, setStatusStartDate] = useState(false);
    const [statusEndDate, setStatusEndDate] = useState(false);
    // ** Chapters are fetched from the search api response in bills table ** //
    // ** This is same approach as Counts for now ** //
    const [chaptersCount, setChaptersCount] = useState(false);
    const [selectedChapters, setSelectedChapters] = useState([]);
    // ** Counts ** //
    const [ chambersCount, setChambersCount ] = useState(false);
    const [ billTypesCount, setBillTypesCount ] = useState(false);
    const [ partyCount, setPartyCount ] = useState(false);
    const [ deadlineCategoriesCount, setDealineCategoriesCount ] = useState(false);

    useEffect(() => {
        setContainer(sidenavContent.current);
    }, []);

    useEffect(() => {
        setSelectedLegislator("");
        setSelectedDrafter("");
    }, [sessionId]);


    if (sidenavContent.current) {
        sidenavContent.current.style.marginLeft = sideNavToggle ? '100px' : '0px';
    }

    return (
        <>
            <Filter 
                sideNavToggle={sideNavToggle} 
                setSideNavToggle={setSideNavToggle} 
                container={container}
                setSessionId={setSessionId}
                sessionInfoForHeader={sessionInfoForHeader} 
                setSessionInfoForHeader={setSessionInfoForHeader}
                setLegislatureId={setLegislatureId}
                setSubjectCode={setSubjectCode}
                sessionId={sessionId}
                legislatureId={legislatureId}
                selectedBillTypes={selectedBillTypes}
                setSelectedBillTypes={setSelectedBillTypes}
                selectedLegislator={selectedLegislator}
                setSelectedLegislator={setSelectedLegislator}
                selectedDrafter={selectedDrafter}
                selectedDeadlines={selectedDeadlines}
                setSelectedDrafter={setSelectedDrafter}
                setSelectedProgress={setSelectedProgress}
                setOffset={setOffset}
                selectedParties={selectedParties}
                setSelectedParties={setSelectedParties}
                selectedChambers={selectedChambers}
                setSelectedChambers={setSelectedChambers}
                selectedCommittee={selectedCommittee}
                setSelectedCommitte={setSelectedCommitte}
                setSelectedDeadlines={setSelectedDeadlines}
                chambersCount={chambersCount}
                billTypesCount={billTypesCount}
                partyCount={partyCount}
                hasAmendment={hasAmendment}
                hasFiscalNotes={hasFiscalNotes}
                hasLegalNotes={hasLegalNotes}
                setHasAmendment={setHasAmendment}
                setHasFiscalNotes={setHasFiscalNotes}
                setHasLegalNotes={setHasLegalNotes}
                deadlineCategoriesCount={deadlineCategoriesCount}
                subjectCode={subjectCode}
                selectedProgress={selectedProgress}
                selectedActionTypes={selectedActionTypes} 
                setSelectedActionTypes={setSelectedActionTypes}
                statusStartDate={statusStartDate}
                setStatusStartDate={setStatusStartDate}
                statusEndDate={statusEndDate}
                setStatusEndDate={setStatusEndDate}
                chaptersCount={chaptersCount}
                setChaptersCount={setChaptersCount} 
                selectedChapters={selectedChapters}
                setSelectedChapters={setSelectedChapters}
            />
            <PreHeader />
            <MDBContainer fluid className="p-0" style={{ backgroundImage: `url(${process.env.PUBLIC_URL + '/bkgd-geometric.png'}) ` }}>
                <div ref={sidenavContent}>
                    <BillsTable 
                        sessionId={sessionId}
                        legislatureId={legislatureId}
                        selectedBillTypes={selectedBillTypes}
                        selectedLegislator={selectedLegislator}
                        selectedDrafter={selectedDrafter}
                        selectedParties={selectedParties}
                        selectedChambers={selectedChambers}
                        selectedCommittee={selectedCommittee}
                        selectedProgress={selectedProgress}
                        selectedDeadlines={selectedDeadlines}
                        selectedActionTypes={selectedActionTypes}
                        hasAmendment={hasAmendment}
                        hasFiscalNotes={hasFiscalNotes}
                        hasLegalNotes={hasLegalNotes}
                        subjectCode={subjectCode}
                        setSideNavToggle={setSideNavToggle} 
                        sideNavToggle={sideNavToggle}
                        offset={offset}
                        setOffset={setOffset}
                        setChambersCount={setChambersCount}
                        setBillTypesCount={setBillTypesCount}
                        setPartyCount={setPartyCount}
                        setDealineCategoriesCount={setDealineCategoriesCount}
                        statusStartDate={statusStartDate}
                        statusEndDate={statusEndDate}
                        chaptersCount={chaptersCount}
                        setChaptersCount={setChaptersCount} 
                        selectedChapters={selectedChapters}
                        setSelectedChapters={setSelectedChapters}
                        sessionInfoForHeader={sessionInfoForHeader}
                    />
                    <AppBuildVersion />
                </div>
            </MDBContainer>
        </>
    );
}

export default Home;