import React, { useState, useEffect } from 'react';
import {useCookies} from "react-cookie";

const semverGreaterThan = (versionA, versionB) => {
    const versionsA = versionA.split(/\./g);
    const versionsB = versionB.split(/\./g);
    while (versionsA.length || versionsB.length) {
        const a = Number(versionsA.shift());
        const b = Number(versionsB.shift());

        // eslint-disable-next-line no-continue
        if (a === b) continue;

        // eslint-disable-next-line no-restricted-globals
        return a > b || isNaN(b);
    }
    return false;
};

export function useEffectOnce(effect) {
    useEffect(effect, []);
}

const AppBuildVersion = (userVersion) => {
    const [cookies, setCookie] = useCookies(['appUser']);
    const [currentBuildVersion, setAppVersion] = useState(cookies.appUser?.appVersion || '0.0.0');

    const getAppVersion = () => {
        fetch('appVersion.json', {
            cache: "no-store",
            headers: {
                'Content-Type': 'application/json',
                'Accept': 'application/json'
            }
        })
            .then(response => response.json())
            .then(data => {
                const shouldForceRefresh = semverGreaterThan(data.appVersion, currentBuildVersion);
                if (shouldForceRefresh) {
                    console.log(`We have a new version - ${data.appVersion}. Should force refresh`);
                    setCookie('appUser', {appVersion: data.appVersion}, {
                        path: '/',
                    });
                    setAppVersion(data.appVersion);
                    window.location.reload();
                } else {
                    console.log(`You already have the latest version - ${data.appVersion}. No cache refresh needed.`);
                }
                setAppVersion(data.appVersion);
            })
    };

    useEffectOnce(() => {
        getAppVersion();
    });

    return (
        <div className="row">
            <div className="col text-end">
                <small>Build: {currentBuildVersion}</small>
            </div>
        </div>
    );
};

export default AppBuildVersion;