import axios from 'axios';
import { MDBTabsPane } from 'mdb-react-ui-kit';
import { arrayBufferPromise } from '../../../helper/utils';
import React, { useEffect, useState, useCallback } from 'react';

const BillText = ({ active, bill, ordinal }) => {
    const [isAdobeDCReady, setIsAdobeDCReady] = useState(false);
    const [noPdfFound, setNoPdfFound] = useState(false);
    const [adobeDCView, setAdobeDCView] = useState(null);
    const [isLoading, setIsLoading] = useState(true);

    const initializeAdobeDC = useCallback(() => {
        if (window.AdobeDC) {
            setIsAdobeDCReady(true);
            return;
        }

        const script = document.createElement('script');
        script.src = 'https://acrobatservices.adobe.com/view-sdk/viewer.js';
        script.async = true;
        script.onload = () => {
            document.addEventListener("adobe_dc_view_sdk.ready", () => {
                setIsAdobeDCReady(true);
            });
        };
        document.head.appendChild(script);
    }, []);

    useEffect(() => {
        initializeAdobeDC();
        return () => {
            if (adobeDCView) {
                adobeDCView.previewFile({
                    content: { promise: Promise.resolve(' ') },
                    metaData: { fileName: 'blank.pdf' }
                });
            }
        };
    }, [initializeAdobeDC, adobeDCView]);

    useEffect(() => {
        if (isAdobeDCReady && active) {
            const adobeDCViewInstance = new window.AdobeDC.View({
                clientId: process.env.REACT_APP_ADOBE_EMBEDED_API_KEY,
                divId: "adobe-dc-view"
            });
            setAdobeDCView(adobeDCViewInstance);
            fetchData(adobeDCViewInstance);
        }
    }, [isAdobeDCReady, active]);

    const fetchData = useCallback(async (viewerInstance) => {
        setIsLoading(true);
        const params = new URLSearchParams({
            legislatureOrdinal: `${ordinal}`,
            sessionOrdinal: `${bill.id.sessionId}`,
            lcnumber: `${bill.id.billDraftNumber}`,
        });

        try {
            const response = await axios.get(`${process.env.REACT_APP_LAWS_BACKEND_HOST}/docs/v1/documents/getBillLcs?${params.toString()}`);

            const sortedData = response.data.length > 0 ? response.data.sort((a, b) => {
                const [fileNameA, numericPartA] = a.fileName.split('_');
                const [fileNameB, numericPartB] = b.fileName.split('_');

                if (fileNameA < fileNameB) return -1;
                if (fileNameA > fileNameB) return 1;

                const isXA = numericPartA.toLowerCase().startsWith('x');
                const isXB = numericPartB.toLowerCase().startsWith('x');

                if (isXA && !isXB) return -1;
                if (!isXA && isXB) return 1;

                const numA = isXA ? -Infinity : parseInt(numericPartA, 10);
                const numB = isXB ? -Infinity : parseInt(numericPartB, 10);

                return numB - numA;
            }) : [];

            const fileVersionToUse = sortedData.length > 0 ? sortedData[0] : null;

            setNoPdfFound(!fileVersionToUse);

            if (fileVersionToUse) {
                const pdfBuffer = await arrayBufferPromise(fileVersionToUse.id);
                viewerInstance.previewFile({
                    content: {
                        promise: Promise.resolve(pdfBuffer),
                    },
                    metaData: {
                        fileName: `${bill.billType}${bill.billNumber}.pdf`
                    }
                }, { showAnnotationTools: false });
            }
        } catch (error) {
            setNoPdfFound(true);
            console.error('Error fetching data: ', error);
        } finally {
            setIsLoading(false);
        }
    }, [ordinal, bill]);

    return (
        <MDBTabsPane show={active}>
            <div className="accordion-body">
                {isLoading && (
                    <p className="mt-3">Loading PDF viewer and document...</p>
                )}
                {!isLoading && noPdfFound && (
                    <p className="mt-3">
                        <strong>
                            No document found for {bill.billType === 'SB' ? 'Senate' : 'House'} Bill
                            NO. {bill.billNumber}
                        </strong>
                    </p>
                )}
            </div>
            <div id="adobe-dc-view" style={{ width: '100%', height: '80vH', display: isLoading ? 'none' : 'block' }} />
        </MDBTabsPane>
    );
};

export default BillText;