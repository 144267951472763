import React, { useEffect, useState } from 'react';
import { MDBSelect, MDBSideNavCollapse, MDBSideNavItem, MDBSideNavLink, MDBSpinner } from 'mdb-react-ui-kit';
import axios from 'axios';

const Subjects = ({ setSubjectCode, setOffset, subjectCode, sessionId }) => {
    const [options, setOptions] = useState([]);
    const [collapse, setCollapse] = useState(false);
    const [selectedSubject, setSelectedSubject] = useState('');
    const [isLoading, setIsLoading] = useState(false);

    useEffect(() => {
        const fetchData = async () => {
            setIsLoading(true);
            try {
                let response;
                let mappedSubjects;

                if (sessionId > 20231) {
                    response = await axios.get(`${process.env.REACT_APP_LAWS_BACKEND_HOST}/bills/v1/subjectCodes`);
                    mappedSubjects = response.data.map(item => ({
                        value: item.id,
                        text: item.description
                    }));
                } else {
                    response = await axios.get(`${process.env.REACT_APP_LAWS_ARCHIVE_BACKEND_HOST}/v1/subjects`);
                    mappedSubjects = response.data.map(item => ({
                        value: item.code,
                        text: item.description
                    }));
                }
               
                const removedDuplicatedSubjects = removeDuplicatesByCode(mappedSubjects);
                const options = [
                    { value: "0", text: "-- Select Subject --", disabled: true },
                    ...removedDuplicatedSubjects
                ];
                setOptions(options);
            } catch (error) {
                console.error('Error fetching data: ', error);
            } finally {
                setIsLoading(false);
            }
        };
        fetchData();
    }, [sessionId]);

    useEffect(() => {
        if (subjectCode.length === 0) {
            setSelectedSubject("0");
        } else {
            setSelectedSubject(subjectCode[0]);
        }
    }, [subjectCode]);

    const removeDuplicatesByCode = (subjects) => {
        const uniqueCodes = new Set();
        return subjects.filter(item => !uniqueCodes.has(item.value) && uniqueCodes.add(item.value));
    };

    const handleSubjectSelection = ({ value }) => {
        if (value === '0' || !value) {
            setSubjectCode([]);
            setSelectedSubject('0');
        } else {
            setSubjectCode([value]);
            setSelectedSubject(value);
        }
        setOffset(0);
    };

    return (
        <MDBSideNavItem>
            <MDBSideNavLink className='accordion-button' icon='angle-down' shouldBeExpanded={collapse} onClick={() => setCollapse(!collapse)}>
                Subject / Keyword
            </MDBSideNavLink>
            <MDBSideNavCollapse show={collapse}>
                <li className="p-3">
                    {isLoading ? (
                        <p>
                            <MDBSpinner size='sm' role='status' tag='span' className='me-2' /> Loading...
                        </p>
                    ) : (
                        <MDBSelect
                            data={options}
                            id="subjectFilter"
                            search
                            clearBtn
                            onValueChange={(value) => handleSubjectSelection(value)}
                            value={selectedSubject}
                        />
                    )}
                </li>
            </MDBSideNavCollapse>
        </MDBSideNavItem>
    );
};

export default Subjects;