import {
    MDBDatatable,
    MDBDropdown,
    MDBDropdownItem,
    MDBDropdownMenu,
    MDBDropdownToggle,
    MDBTabsPane
} from 'mdb-react-ui-kit';
import React, {useEffect, useState} from 'react';
import {format, parseISO} from 'date-fns';
import {CSVLink} from 'react-csv';
import {dateHelpers} from '../../../helper/dates';
import jsPDF from 'jspdf';
import 'jspdf-autotable';
import axios from "axios";

const convertISOToReadableTimeFormat = (date) => {
    return format(parseISO(date), "MM/dd/yy");
};

const formatDate = (dateString, format = 'YYYY-MM-DD') => {
    const date = new Date(dateString);
    const formatTokens = {
        YYYY: date.getFullYear(),
        MM: String(date.getMonth() + 1).padStart(2, '0'),
        DD: String(date.getDate()).padStart(2, '0'),
        M: date.getMonth() + 1,
        D: date.getDate(),
        YY: date.getFullYear().toString().slice(-2)
    };

    return format.replace(/YYYY|MM|DD|M|D|YY/g, match => formatTokens[match]);
}

const tableHeading = [
    {label: 'Date', field: 'Date'},
    {label: 'Action', field: 'Action'},
    {label: 'Committee', field: 'Committee'},
    {label: 'Yes Votes', field: 'Votes (Yes)'},
    {label: 'No Votes', field: 'Votes (No)'},
    {label: 'Media', field: 'Media'}
];

const generateMediaUI = (media) => {
    if (!Array.isArray(media) || media.length < 2) {
        return null;
    }
    const baseUrl = 'http://sg001-harmony.sliq.net/00309/Harmony/en/PowerBrowser/PowerBrowserV2/20170221/-1/';
    const audioUrl = `${baseUrl}${media[0].harmonyClipId}?agendaId=${media[0].harmonyMarkerId}`;
    const videoUrl = `${baseUrl}${media[1].harmonyClipId}?agendaId=${media[1].harmonyMarkerId}`;

    return (
        <div className='text-center'>
            <a target="_blank" href={audioUrl} className="mx-2" aria-label="Audio">
                <i className="fa-solid fa-volume-low" aria-hidden="true"></i>
            </a>
            <a target="_blank" href={videoUrl} className="mx-2" aria-label="Video">
                <i className="fa-solid fa-film" aria-hidden="true"></i>
            </a>
        </div>
    );
}

const formatRowStructureForExport = (billActions) => {
    return billActions.map((item) => {
        return {
            "Date": convertISOToReadableTimeFormat(item?.date),
            "Action": item?.actionType?.description ? item.actionType.description : '',
            "Committee": item?.committee?.committeeName ? item.committee.committeeName : '',
            "Votes (Yes)": item?.yesVotes ? item?.yesVotes : '',
            "Votes (No)": item?.noVotes ? item?.noVotes : '',
            "Media": item?.media ? 'Media' : '' // This is for export, keeping it simple.
        };
    });
};

const cache = new Map();

const legislativeCalendarCache = new Map();
const inProgressRequests = new Map();

const fetchLegislativeCalendarDay = async (dateLookup) => {
    // Generate a unique cache key based on the dateLookup
    const cacheKey = dateLookup;

    // Check if the result is already in the cache
    if (legislativeCalendarCache.has(cacheKey)) {
        return legislativeCalendarCache.get(cacheKey);
    }

    // Check if the request is already in progress
    if (inProgressRequests.has(cacheKey)) {
        return inProgressRequests.get(cacheKey);
    }

    // Make the request and store the promise in the in-progress cache
    const requestPromise = axios.post(
        `${process.env.REACT_APP_LAWS_ARCHIVE_BACKEND_HOST}/v1/legislativeCalendars/byDate`,
        { date: dateLookup },
        {
            headers: {
                'Content-Type': 'application/json',
                'Accept': '*/*'
            }
        }
    ).then(({ data }) => {
        const result = data[0];
        // Store the result in the cache
        legislativeCalendarCache.set(cacheKey, result);
        // Remove the in-progress request
        inProgressRequests.delete(cacheKey);
        return result;
    }).catch(error => {
        // Remove the in-progress request in case of error
        inProgressRequests.delete(cacheKey);
        throw error;
    });

    inProgressRequests.set(cacheKey, requestPromise);

    return requestPromise;
};

const formatRowStructureForStatusDatatable = ({floorVoteHouseDirectory, floorVoteSenateDirectory, voteSheetLinks, billActions}) => {
    return billActions.reduce((formattedRows, item) => {
        const actionDescription = item?.actionType?.description || '';
        let floorVoteDirectory;

        // Only set floorVoteDirectory if the corresponding directory exists
        if (actionDescription[1] === "H" && floorVoteHouseDirectory) {
            floorVoteDirectory = floorVoteHouseDirectory;
        } else if (actionDescription[1] === "S" && floorVoteSenateDirectory) {
            floorVoteDirectory = floorVoteSenateDirectory;
        }

        const voteSheetLink = (item?.yesVotes !== undefined || item?.noVotes !== undefined) && floorVoteDirectory ? (
            <VoteSheetLink
                voteSheetLinks={voteSheetLinks}
                floorVoteDirectory={floorVoteDirectory}
                voteSeq={item.voteSeq}
                statusItemDate={item.date}
                itemActionCode={item.code}
                voteCount="" // Placeholder, we will use this link in both columns
            />
        ) : null;

        formattedRows.push({
            "Date": convertISOToReadableTimeFormat(item?.date),
            "Action": actionDescription,
            "Committee": item?.committee?.committeeName || '',
            "Votes (Yes)": voteSheetLink ? (
                <>{React.cloneElement(voteSheetLink, { voteCount: item?.yesVotes ?? '' })}</>
            ) : item?.yesVotes ?? '',
            "Votes (No)": voteSheetLink ? (
                <>{React.cloneElement(voteSheetLink, { voteCount: item?.noVotes ?? '' })}</>
            ) : item?.noVotes ?? '',
            "Media": item?.media ? <>{generateMediaUI(item.media)}</> : ''
        });
        return formattedRows;
    }, []);
}

const exportData = (data, fileName, format) => {
    if (format === 'csv') {
        return (
            <CSVLink className='csv-link' id="csvLink" data={data} filename={`${fileName}.csv`}>Excel/CSV</CSVLink>
        );
    } else if (format === 'pdf') {
        const doc = new jsPDF();
        doc.text('Exported Data', 10, 10);
        const headers = Object.keys(data[0]);
        const tableData = data.map(row => headers.map(header => row[header]));
        doc.autoTable({head: [headers], body: tableData});
        doc.save(`${fileName}.pdf`);
        return null;
    } else if (format === 'print') {
        window.print();
    } else {
        console.error('Unsupported export format');
        return null;
    }
};

const isSameDate = (date1, date2) => {
    if (!(date1 instanceof Date) || !(date2 instanceof Date)) {
        return false;
    }
    return (
        date1.getFullYear() === date2.getFullYear() &&
        date1.getMonth() === date2.getMonth() &&
        date1.getDate() === date2.getDate()
    );
};

const extractDateFromFilename = (filename) => {
    const datePattern = /\d{6}/;
    const match = filename.match(datePattern);

    if (match) {
        const dateString = match[0];
        const year = parseInt(dateString.slice(0, 2), 10) + 2000;
        const month = parseInt(dateString.slice(2, 4), 10) - 1;
        const day = parseInt(dateString.slice(4, 6), 10);

        return new Date(year, month, day);
    }

    return null;
}

const handleVoteSheetVotesLink = (documentId) => {
    async function fetchDataAndGotoSheetLinkUrl(documentId) {
        try {
            const {data} = await axios.post(`${process.env.REACT_APP_DOCS_BACKEND_HOST}/v1/documents/shortPdfUrl?documentId=${documentId}`);
            const pdfUrl = data;
            setTimeout(() => {
                const newWindow = window.open(pdfUrl, '_blank');
                if (!newWindow || newWindow.closed || typeof newWindow.closed == 'undefined') {
                    window.location.href = pdfUrl;
                }
            }, 0);
        } catch (error) {
            console.error("Error fetching vote link data: ", error);
            throw error;
        }
    }

    fetchDataAndGotoSheetLinkUrl(documentId);
}


const hasVoteSheetVotesLink = async (voteSheetLinks, floorVoteDirectory, voteSeq, statusItemDate, voteCount) => {
    // Return a placeholder if voteCount is null
    if (voteCount === null) {
        return " - ";
    }

    // Helper function to create a link element
    const createLink = (id) => (
        <a
            href="#"
            onClick={(e) => {
                e.preventDefault();
                handleVoteSheetVotesLink(id);
            }}
        >
            {voteCount}
        </a>
    );

    // Generate a unique cache key based on the function parameters
    const cacheKey = `${floorVoteDirectory}-${voteSeq}-${statusItemDate}-${voteCount}`;

    // Check if the result is already in the cache
    if (cache.has(cacheKey)) {
        return cache.get(cacheKey);
    }

    // If voteSeq is provided, fetch the floor vote sheet
    if (voteSeq) {
        const finalVoteSeq = voteSeq.slice(1);

        try {
            const formattedDate = formatDate(statusItemDate, 'MM/DD/YYYY');
            const { id: { legDD } } = await fetchLegislativeCalendarDay(formattedDate);
            const realLegDD = legDD.toString().padStart(3, '0');
            const floorVoteFileLookup = `${realLegDD}.${finalVoteSeq.padStart(4, '0')}.pdf`;

            // Generate a unique cache key for the listByFolderFileName request
            const listByFolderFileNameCacheKey = `${floorVoteDirectory}-${floorVoteFileLookup}`;

            // Check if the request is already in progress
            if (inProgressRequests.has(listByFolderFileNameCacheKey)) {
                return inProgressRequests.get(listByFolderFileNameCacheKey).then(data => {
                    const result = createLink(data.id);
                    cache.set(cacheKey, result);
                    return result;
                });
            }

            // Make the request and store the promise in the in-progress cache
            const requestPromise = axios.get(
                `${process.env.REACT_APP_LAWS_BACKEND_HOST}/docs/v1/documents/listByFolderFileName`,
                {
                    params: {
                        folderId: floorVoteDirectory,
                        fileName: floorVoteFileLookup,
                    },
                }
            ).then(({ data }) => {
                // Store the result in the listByFolderFileName cache
                cache.set(listByFolderFileNameCacheKey, data[0]);
                // Remove the in-progress request
                inProgressRequests.delete(listByFolderFileNameCacheKey);
                return data[0];
            }).catch(error => {
                // Remove the in-progress request in case of error
                inProgressRequests.delete(listByFolderFileNameCacheKey);
                throw error;
            });

            inProgressRequests.set(listByFolderFileNameCacheKey, requestPromise);

            // Wait for the request to complete and create the link
            const data = await requestPromise;
            const result = createLink(data.id);
            // Store the result in the main cache
            cache.set(cacheKey, result);
            return result;
        } catch (error) {
            console.error('Error fetching floor vote sheet:', error);
            return voteCount; // Return voteCount if an error occurs
        }
    } else {
        // If voteSeq is not provided, find a matching vote sheet link
        const itemDate = new Date(statusItemDate);

        const matchingVoteSheetLink = voteSheetLinks.find((vote) => {
            const voteSheetDateExtracted = extractDateFromFilename(vote.fileName);
            return isSameDate(itemDate, voteSheetDateExtracted);
        });
        const result = matchingVoteSheetLink ? createLink(matchingVoteSheetLink.id) : voteCount;
        // Store the result in the cache
        cache.set(cacheKey+itemDate, result);
        return result;
    }
};

// New component to handle the asynchronous operation
const VoteSheetLink = ({ voteSheetLinks, floorVoteDirectory, voteSeq, statusItemDate, itemActionCode, voteCount }) => {
    const [link, setLink] = useState(null);

    // Exclude these specific actionCodes
    const excludedCodes = [124, 525];

    if (excludedCodes.includes(itemActionCode)) {
        return voteCount;
    }

    useEffect(() => {
        const fetchLink = async () => {
            const result = await hasVoteSheetVotesLink(voteSheetLinks, floorVoteDirectory, voteSeq, statusItemDate, voteCount);
            setLink(result);
        };

        fetchLink();
    }, [voteSheetLinks, floorVoteDirectory, voteSeq, statusItemDate, voteCount]);

    if (link === null) {
        return voteCount;
    }

    return <>{link}</>;
};

const Status = ({active, bill, ordinal}) => {
    const [showVotesOnly, setShowVotesOnly] = useState(false);
    const [formattedData, setFormattedData] = useState({columns: tableHeading, rows: []});
    const [originalData, setOriginalData] = useState([]);
    const [tableClick, setTableClick] = useState(false);
    const [billVoteSheetLinks, setBillVoteSheetLinks] = useState([]);

    useEffect(() => {
        async function fetchDataAndSetVoteSheetLinks() {
            try {
                if (bill.sessionLawChapterNumber !== null && !bill.id.billDraftNumber || !bill.billType || !bill.billNumber) throw new Error("bill details not available");
                const {data} = await axios.get(`${process.env.REACT_APP_DOCS_BACKEND_HOST}/v1/documents/getBillVoteSheets?legislatureOrdinal=${ordinal}&sessionOrdinal=${bill.id.sessionId}&billType=${bill.billType.trim()}&billNumber=${bill.billNumber}`);
                setBillVoteSheetLinks(data);
            } catch (error) {
                console.error('Error fetching other bill vote sheets: ', error);
            }
        }

        fetchDataAndSetVoteSheetLinks();
    }, [bill.id.sessionId, bill.id.billDraftNumber, bill.billType, bill.billNumber]);

    useEffect(() => {
        async function fetchBillMediaLinks() {
            try {
                if (
                    bill.sessionLawChapterNumber !== null &&
                    (!bill.id.sessionId || !bill.id.billDraftNumber || !bill.billType || !bill.billNumber)
                ) {
                    throw new Error("Bill details not available");
                }
                const { data } = await axios.get(
                    `${process.env.REACT_APP_MEDIA_ARCHIVE_BACKEND_HOST}/v1/mediaLinks?sessionId=${bill.id.sessionId}&billDraftNumber=${bill.id.billDraftNumber}&billType=${bill.billType.trim()}&billNumber=${bill.billNumber}`
                );
                return data;
            } catch (error) {
                console.error("Error fetching data: ", error);
                return null;
            }
        }

        async function processBillActions(mediaLinks) {
            try {
                const includedCodes = [112, 113, 156, 157, 159, 161, 172, 210, 220, 512, 513, 556, 557, 558, 572, 610, 612, 619];
                const excludedCodes = [116, 117, 153, 516, 553];
                const processedActions = bill.billActions.map(billAction => {
                    if (mediaLinks) {
                        const matches = mediaLinks.filter(item => formatDate(item.videoDate) === formatDate(billAction.date));
                        if (matches.length > 0) {
                            const filteredMatches = billAction.actionType.code &&
                            includedCodes.includes(billAction.actionType.code) &&
                            !excludedCodes.includes(billAction.actionType.code) ? matches : [];
                            return { ...billAction, media: filteredMatches.length > 0 ? filteredMatches : false };
                        }
                        return { ...billAction, media: false };
                    } else {
                        return { ...billAction, media: false };
                    }
                });

                let floorVoteHouseDirectoryId = null;
                let floorVoteSenateDirectoryId = null;

                try {
                    const encodedRootFolder = encodeURIComponent(`${ordinal}/${bill.id.sessionId}/Bills/FloorVotes/House`);
                    const folderResponse = await axios.get(
                        `${process.env.REACT_APP_DOCS_BACKEND_HOST}/v1/folders/findByPath?path=${encodedRootFolder}`
                    );
                    floorVoteHouseDirectoryId = folderResponse.data?.id ?? null;
                } catch (error) {
                    console.warn("Error fetching House directory:", error);
                }

                try {
                    const encodedSenateRootFolder = encodeURIComponent(`${ordinal}/${bill.id.sessionId}/Bills/FloorVotes/Senate`);
                    const folderSenateResponse = await axios.get(
                        `${process.env.REACT_APP_DOCS_BACKEND_HOST}/v1/folders/findByPath?path=${encodedSenateRootFolder}`
                    );
                    floorVoteSenateDirectoryId = folderSenateResponse.data?.id ?? null;
                } catch (error) {
                    console.warn("Error fetching Senate directory:", error);
                }

                const formattedRow = formatRowStructureForStatusDatatable({
                    floorVoteHouseDirectory: floorVoteHouseDirectoryId,
                    floorVoteSenateDirectory: floorVoteSenateDirectoryId,
                    voteSheetLinks: billVoteSheetLinks,
                    billActions: processedActions
                });

                setFormattedData({ columns: tableHeading, rows: formattedRow });
                setOriginalData(formattedRow);
            } catch (error) {
                console.error("Error processing bill actions: ", error);
            }
        }

        async function fetchDataAndProcess() {
            const mediaLinks = await fetchBillMediaLinks();
            await processBillActions(mediaLinks);
        }

        fetchDataAndProcess();
    }, [billVoteSheetLinks, bill.id.sessionId, bill.id.billDraftNumber, bill.billType, bill.billNumber]);


    useEffect(() => {
        const getVoteCount = (vote) => {
            if (typeof vote === 'string') {
                return vote;
            }
            if (vote && vote.props && vote.props.children && vote.props.children.props) {
                return parseInt(vote.props.children.props.voteCount) || 0;
            }
            return 0;
        };

        let newRows;
        if (showVotesOnly) {
            newRows = originalData.filter(row => {
                const yesVotes = getVoteCount(row["Votes (Yes)"]);
                const noVotes = getVoteCount(row["Votes (No)"]);
                return yesVotes > 0 || noVotes > 0;
            });
        } else {
            newRows = originalData;
        }

        const newFormattedData = {columns: tableHeading, rows: newRows};
        setFormattedData(newFormattedData);

    }, [showVotesOnly, originalData, tableHeading]);

    const handleCsvClick = () => {
        const link = document.getElementById('csvLink');
        link.click();
    };

    useEffect(() => {
        const addClassToRow = () => {
            const table = document.querySelector(".status-datatable");
            if (table) {
                const rows = table.querySelectorAll("tbody tr");
                rows.forEach(row => {
                    const yesVotesCell = row.cells[3];
                    const noVotesCell = row.cells[4];
                    let yesVotes = 0;
                    let noVotes = 0;
                    if (yesVotesCell) yesVotes = parseInt(yesVotesCell.innerText);
                    if (noVotesCell) noVotes = parseInt(noVotesCell.innerText);
                    for (let i = 0; i < row.cells.length; i++) {
                        row.classList.remove('yes-leading', 'no-leading', 'tie-leading');
                        if (yesVotes > noVotes) {
                            row.classList.add('yes-leading');
                        } else if (yesVotes < noVotes) {
                            row.classList.add('no-leading');
                        } else if (yesVotes == noVotes) {
                            row.classList.add('tie-leading');
                        }
                    }
                });
            }
        };
        setTimeout(() => addClassToRow(), 20);
    }, [formattedData, tableClick]);

    return (
        <MDBTabsPane show={active}>
            <div className="accordion-body">
                <div className='row py-3 g-0'>
                    <div className='col-sm align-items-center text-center text-md-start mb-3 mb-md-0'>
                        <div className="form-check form-check-inline">
                            <input
                                className="form-check-input"
                                type="checkbox"
                                id="showVotesOnly"
                                checked={showVotesOnly}
                                onChange={(e) => {
                                    setShowVotesOnly(!showVotesOnly)
                                }}
                            />
                            <label className="form-check-label" htmlFor="showVotesOnly">Show Only Votes</label>
                        </div>
                    </div>
                    <div className='col-sm align-items-center'>
                        {/* <input
                            style={{ width: '100%' }}
                            className='form-control'
                            placeholder='Search Status...'
                            type="text"
                            value={searchTerm}
                            onChange={(e) => setSearchTerm(e.target.value)}
                        /> */}
                    </div>
                    <div className='col-auto mt-sm-0 mx-auto mt-3'>
                        <div className='d-flex justify-content-end p-0'>
                            <MDBDropdown>
                                <MDBDropdownToggle className='shadow-none ms-1'><i
                                    className="fa-light fa-cloud-arrow-down"
                                    aria-hidden="true"></i> Export</MDBDropdownToggle>
                                <MDBDropdownMenu>
                                    <MDBDropdownItem link onClick={(e) => {
                                        e.preventDefault();
                                        handleCsvClick()
                                    }}>{exportData(formattedData.rows, `Status_details_${dateHelpers.getCurrentDate()}`, 'csv')}</MDBDropdownItem>
                                    <MDBDropdownItem link onClick={(e) => {
                                        e.preventDefault();
                                        exportData(formatRowStructureForExport(bill.billActions), `Status_details_${dateHelpers.getCurrentDate()}`, 'pdf')
                                    }}>PDF/Print</MDBDropdownItem>
                                </MDBDropdownMenu>
                            </MDBDropdown>
                        </div>
                    </div>
                </div>
                <div id="table-actions_wrapper" className="dataTables_wrapper dt-bootstrap5 no-footer">
                    <div className='row'>
                        <MDBDatatable
                            data={formattedData}
                            fixedHeader={true}
                            bordered
                            pagination={false}
                            entries={1000}
                            loadingMessage={"Loading..."}
                            noFoundMessage={"No matching data"}
                            className='status-datatable'
                            onClick={() => {
                                setTableClick(!tableClick)
                            }}
                        />
                    </div>
                    <div className="row">
                        <div className="col-md-5">
                            <div className="dataTables_info" id="table-actions_info" role="status" aria-live="polite">
                                <small>Showing 1
                                    to {formattedData.rows.length} of {formattedData.rows.length} entries</small></div>
                        </div>
                        <div className="col-md-7">
                            <div className="dataTables_paginate paging_simple_numbers" id="table-actions_paginate">
                                <ul className="pagination mt-3">
                                    <li className="paginate_button page-item previous disabled"
                                        id="table-actions_previous">
                                        <a aria-controls="table-actions" aria-disabled="true" role="link"
                                           data-dt-idx="previous" tabIndex="0" className="page-link">Previous</a>
                                    </li>
                                    <li className="paginate_button page-item active">
                                        <a href="#" aria-controls="table-actions" role="link" aria-current="page"
                                           data-dt-idx="0" tabIndex="0" className="page-link">1</a>
                                    </li>
                                    <li className="paginate_button page-item next disabled" id="table-actions_next">
                                        <a aria-controls="table-actions" aria-disabled="true" role="link"
                                           data-dt-idx="next" tabIndex="0" className="page-link">Next</a>
                                    </li>
                                </ul>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </MDBTabsPane>
    );
};

export default Status;
