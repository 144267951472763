import React from 'react';
import {
    MDBSideNav,
    MDBSideNavMenu,
    MDBScrollbar,
    MDBBtn,
} from 'mdb-react-ui-kit';
import Session from './MainFilters/Session';
import Subjects from './MainFilters/Subjects';
import Committee from './MainFilters/Committee';
import Progress from './MainFilters/Progress';
import Legislator from './MainFilters/Legislator';
import Party from './MainFilters/Party';
import BillType from './MainFilters/BillType';
import Chamber from './MainFilters/Chamber';
import Status from './MainFilters/Status';
import OtherFilters from './OtherFilters/OtherFilters';
import FilterHeader from './FilterHeader';
import Chapters from './MainFilters/Chapters';

const Filter = ({ sideNavToggle, setSideNavToggle, container, setSessionId, setSubjectCode, sessionId, 
                  selectedBillTypes, setSelectedBillTypes, selectedLegislator, setSelectedLegislator, 
                  setOffset, selectedDrafter, setSelectedDrafter, selectedParties, setSelectedParties,
                  selectedChambers, setSelectedChambers,  billTypesCount,  partyCount, chambersCount,
                  selectedCommittee, setSelectedCommitte, setSelectedProgress, setHasAmendment, setHasFiscalNotes, 
                  setHasLegalNotes, deadlineCategoriesCount, selectedDeadlines, setSelectedDeadlines , setLegislatureId, 
                  legislatureId, hasAmendment, hasFiscalNotes, hasLegalNotes, subjectCode, selectedProgress, selectedActionTypes, 
                  setSelectedActionTypes, statusStartDate, setStatusStartDate, statusEndDate, setStatusEndDate, 
                  chaptersCount, setChaptersCount, selectedChapters, setSelectedChapters, sessionInfoForHeader, setSessionInfoForHeader
                }) => {
    const handleClearFilter = () => {
        setSessionId(20231);
        setSubjectCode([]);
        setSelectedBillTypes([]);
        setSelectedLegislator("");
        setOffset(0);
        setSelectedDrafter("");
        setSelectedParties([]);
        setSelectedChambers([]);
        setSelectedCommitte("");
        setSelectedProgress("");
        setSelectedActionTypes([]);
        setHasAmendment(false);
        setHasFiscalNotes(false);
        setHasLegalNotes(false);
        setSelectedDeadlines([]);
        setLegislatureId(1);
        setStatusEndDate(null);
        setStatusStartDate(null);
        setSelectedChapters([]);
    }
                 
    
    return (
        <>
            <MDBSideNav isOpen={sideNavToggle} style={{ transition: 'all 0.2s linear 0s' , width:'350px', position: 'fixed' }} contentRef={container} mode={'side'} absolute getOpenState={(e) => setSideNavToggle(e)}>
                <MDBSideNavMenu>
                    <FilterHeader setSideNavToggle={setSideNavToggle} />
                    <Session sessionId={sessionId} setSessionId={setSessionId} setOffset={setOffset} setSelectedLegislator={setSelectedLegislator} setLegislatureId={setLegislatureId} sessionInfoForHeader={sessionInfoForHeader} setSessionInfoForHeader={setSessionInfoForHeader}/>
                    <Chamber selectedChambers={selectedChambers} setSelectedChambers={setSelectedChambers} setOffset={setOffset} chambersCount={chambersCount}/>
                    <BillType selectedBillTypes={selectedBillTypes} setSelectedBillTypes={setSelectedBillTypes} setOffset={setOffset} billTypesCount={billTypesCount} sessionId={sessionId}/>
                    <Chapters setChaptersCount={setChaptersCount} chaptersCount={chaptersCount} selectedChapters={selectedChapters} setSelectedChapters={setSelectedChapters} setOffset={setOffset}/>
                    <Subjects setSubjectCode={setSubjectCode} setOffset={setOffset} subjectCode={subjectCode} sessionId={sessionId}/> 
                    <Legislator sessionId={sessionId} selectedLegislator={selectedLegislator} setSelectedLegislator={setSelectedLegislator}  setOffset={setOffset} legislatureId={legislatureId}/>
                    <Committee sessionId={sessionId} selectedCommittee={selectedCommittee} setSelectedCommitte={setSelectedCommitte} setOffset={setOffset} legislatureId={legislatureId} />
                    <Party sessionId={sessionId} setOffset={setOffset} selectedParties={selectedParties} setSelectedParties={setSelectedParties}  partyCount={partyCount}  setSelectedLegislator={setSelectedLegislator} />
                    <Progress sessionId={sessionId} setOffset={setOffset}  setSelectedProgress={setSelectedProgress} selectedProgress={selectedProgress}/>
                    <Status 
                        selectedActionTypes={selectedActionTypes} 
                        setSelectedActionTypes={setSelectedActionTypes} 
                        setOffset={setOffset}
                        statusStartDate={statusStartDate}
                        setStatusStartDate={setStatusStartDate}
                        statusEndDate={statusEndDate}
                        setStatusEndDate={setStatusEndDate}
                    />
                    <OtherFilters 
                        sessionId={sessionId} 
                        selectedDrafter={selectedDrafter} 
                        setSelectedDrafter={setSelectedDrafter} 
                        setOffset={setOffset}  
                        setHasAmendment={setHasAmendment} 
                        setHasFiscalNotes={setHasFiscalNotes} 
                        deadlineCategoriesCount={deadlineCategoriesCount}
                        selectedDeadlines={selectedDeadlines}
                        setSelectedDeadlines={setSelectedDeadlines}
                        hasAmendment={hasAmendment}
                        hasFiscalNotes={hasFiscalNotes}
                        hasLegalNotes={hasLegalNotes}
                        setHasLegalNotes={setHasLegalNotes}
                    />
                    <div className='text-center'>
                        <MDBBtn onClick={handleClearFilter} className='shadow-none m-3' size='sm' color='dark'>Clear Filter</MDBBtn>
                    </div>
                </MDBSideNavMenu>
            </MDBSideNav>
        </>
    );
}

export default Filter;


