import React, { useEffect, useState } from 'react';
import { MDBCheckbox, MDBSpinner } from 'mdb-react-ui-kit';
import axios from 'axios';

const Deadlines = ({ sessionId, deadlineCategoriesCount, selectedDeadlines, setSelectedDeadlines }) => {
    const [deadLines, setDeadLines] = useState([]);
    const [isLoading, setIsLoading] = useState(true);

    const convertToTitleCase = (str) => {
        return str.replace(/\w\S*/g, function (txt) {
            return txt.charAt(0).toUpperCase() + txt.substr(1).toLowerCase();
        });
    }

    const arrangeDeadlinesInAlphabeticalOrder = arr => arr.sort((a, b) => a.name.localeCompare(b.name));

    useEffect(() => {
        const fetchData = async () => {
            let mappedData;
            try {
                if (sessionId > 20231) {
                    const { data } = await axios.get(`${process.env.REACT_APP_LAWS_BACKEND_HOST}/legislators/v1/deadlineCodes`);
                    mappedData = data.map( item => {
                        return { code: item.id, name: item.description }
                    });
                } else {
                    const { data } = await axios.get(`${process.env.REACT_APP_LAWS_ARCHIVE_BACKEND_HOST}/v1/deadlineCategory/findBySession?session=${sessionId}`);
                    mappedData = data.map( item => {
                        return { code: item.id.code, name: item.name }
                    });
                }
                setDeadLines(arrangeDeadlinesInAlphabeticalOrder(mappedData));
                setIsLoading(false);
            } catch (error) {
                console.error('Error fetching data: ', error);
            }
        };
        setIsLoading(true)
        fetchData();
    }, [sessionId]);

    const handleCheckboxChange = (event) => {
        const deadlineCode = event.target.value;
        setSelectedDeadlines(prevSelectedDeadlines => {
            if (event.target.checked) {
                return [...prevSelectedDeadlines, deadlineCode];
            } else {
                return prevSelectedDeadlines.filter(code => code !== deadlineCode);
            }
        });
    };

    return (
        <div>
            <label className="form-label">Deadline</label>
            {   
            isLoading ? <p><MDBSpinner size='sm' role='status' tag='span' className='me-2' /> Loading...</p>:
                deadLines.map((deadline,index) => {
                    return <div key={index} className="d-flex justify-content-between align-items-start">
                        <div className="form-check">
                            <MDBCheckbox
                                value={deadline.code} 
                                className='legmt-checkbox' 
                                name='checkNoLabel'
                                onChange={handleCheckboxChange}
                                checked={selectedDeadlines.includes(deadline.code)}
                            />
                            <label  className="form-check-label">{convertToTitleCase(deadline.name)}</label>
                        </div>
                        <div>
                            {/* <span className="badge text-bg-light">{deadlineCategoriesCount ? deadlineCategoriesCount[deadline.id.code] ? deadlineCategoriesCount[deadline.id.code] : 0 : 0}</span> */}
                        </div>
                    </div>
                })
            }
        </div>
    );
}

export default Deadlines;
