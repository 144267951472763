import React, { useState, useEffect, useCallback } from 'react';
import axios from 'axios';
import { Link } from 'react-router-dom';
import { format, parseISO } from 'date-fns';
import { MDBDatatable, MDBFooter, MDBBadge } from 'mdb-react-ui-kit';
import './BillsTable.css';
import Pagination from './Pagination';
import Header from './Header';

const tableHeading = [
    { label: 'Bill #', field: 'Bill #', sort: false },
    { label: 'LC #', field: 'LC #', sort: false },
    { label: 'Short Title', field: 'Short Title', sort: false },
    { label: 'Primary Sponsor', field: 'Primary Sponsor', sort: false },
    { label: 'Status Date', field: 'Status Date', sort: false },
    { label: 'Status', field: 'Status', sort: false }
];

const BillsTable = ({ sessionId, legislatureId, subjectCode, setSideNavToggle, sideNavToggle, selectedBillTypes, selectedLegislator,
    offset, setOffset, selectedDrafter, selectedParties, selectedChambers, setChambersCount, setBillTypesCount,
    setPartyCount, selectedCommittee, selectedProgress, hasAmendment, hasFiscalNotes, hasLegalNotes, setDealineCategoriesCount, 
    selectedDeadlines, selectedActionTypes, statusStartDate, statusEndDate, chaptersCount, setChaptersCount, 
    selectedChapters, setSelectedChapters, sessionInfoForHeader }) => {

    const [bills, setBills] = useState({ columns: [], rows: [] });
    const [dataForExport, setDataForExport] = useState(tableHeading);
    const [totalPages, setTotalPages] = useState(500);
    const [lastPage, setLastPage] = useState(true);
    const [loading, setLoading] = useState(true);
    const [billSearchText, setBillSearchText] = useState("");
    const [exportDataReady, setExportDataReady] = useState(false);

    const convertISOToReadableTimeFormat = (date) => { return format(parseISO(date), "MM/dd/yy") };

    const [abortControllerQueue, setAbortControllerQueue] = useState([]);

    // dms
    const [exportData, setExportData] = useState(null);

    const getPrimarySponsor = (primarySponsorBillRole, billType) => {
        if (primarySponsorBillRole.length === 0) {
            return null;
        }
        const primarySponsor = primarySponsorBillRole[0];
        if (primarySponsor && primarySponsor.lawEntity && primarySponsor.legislator && primarySponsor.legislator.politicalParty) {
            return `${primarySponsor.lawEntity.lastName}, ${primarySponsor.lawEntity.firstName} (${primarySponsor.legislator.politicalParty.partyCode}) ${billType ? billType.startsWith('H') ? "HD" : "SD" : ""} ${primarySponsor.legislator.districtNumber}`;
        }
        return null;
    };

    const formatBillStructureForDataTables = (content) => {
        return content.map(item => {
            return {
                "Bill #": item.billNumber && item.billType ? <Link to={`/bill/${sessionId}/${item.id.billDraftNumber}?open_tab=sum`} className='text-decoration-underline legmt-link' target="_blank">{item.billType} {item.billNumber}</Link> : <MDBBadge color='secondary' light>Not Available</MDBBadge>,
                "LC #": item.id.billDraftNumber ? <Link to={`/lc/bill/${sessionId}/${item.id.billDraftNumber}`} className='text-decoration-underline legmt-link' target="_blank">{item.id.billDraftNumber}</Link> : <MDBBadge color='secondary' light>Not Available</MDBBadge>,
                "Short Title": item.shortTitle ? <span>{item.shortTitle}</span> : <MDBBadge color='secondary' light>Not Available</MDBBadge>,
                "Primary Sponsor": getPrimarySponsor(item.primarySponsorBillRoles, item.billType) ? <span>{getPrimarySponsor(item.primarySponsorBillRoles, item.billType)}</span> : <MDBBadge color='secondary' light>Not Available</MDBBadge>,
                "Status Date": item.lastStatusDate ? <span>{convertISOToReadableTimeFormat(item.billActions[0].date)}</span> : <span>{convertISOToReadableTimeFormat(item.billActions[0].date)}</span>,
                "Status": (() => {
                    const actionCode = item.billActions[0].actionType.code;
                    const isDangerCode = actionCode === 275 || actionCode === 274 || actionCode === 40 || actionCode === 675 || actionCode === 674;

                    if (isDangerCode) {
                        return <MDBBadge className='w-100' color='danger' light>{item.billActions[0].actionType.description}</MDBBadge>;
                    } else {
                        return <MDBBadge color='custom1' className='badge w-100'>{item.billActions[0].actionType.description}</MDBBadge>;
                    }
                })()
            };
        });
    };

    const formatLawsBillStructureForDataTables = (content) => {
        return content.map(item => {
            const sessionId = item.sessionId;
            const latestBillStatus = item.draft.billStatuses.length > 0 
                ? item.draft.billStatuses[item.draft.billStatuses.length - 1] 
                : null;
    
            return {
                "Bill #": item.billNumber && item.billType ? (
                    <Link to={`/bill/${sessionId}/${item.id}?open_tab=sum`} className='text-decoration-underline legmt-link' target="_blank">
                        {item.billType.code} {item.billNumber}
                    </Link>
                ) : (
                    <MDBBadge color='secondary' light>Not Available</MDBBadge>
                ),
                "LC #": item.draft.draftNumber ? (
                    <Link to={`/lc/bill/${sessionId}/${item.draft.id}`} className='text-decoration-underline legmt-link' target="_blank">
                        {item.draft.draftNumber}
                    </Link>
                ) : (
                    <MDBBadge color='secondary' light>Not Available</MDBBadge>
                ),
                "Short Title": item.draft.shortTitle ? (
                    <span>{item.draft.shortTitle}</span>
                ) : (
                    <MDBBadge color='secondary' light>Not Available</MDBBadge>
                ),
                "Primary Sponsor": item.sponsor ? (
                    <span>{`${item.sponsor.firstName} ${item.sponsor.lastName}`}</span>
                ) : (
                    <MDBBadge color='secondary' light>Not Available</MDBBadge>
                ),

                "Status Date": latestBillStatus ? (
                    <span>{convertISOToReadableTimeFormat(latestBillStatus.timeStamp)}</span>
                ) : (
                    <MDBBadge color='secondary' light>Not Available</MDBBadge>
                ),
                "Status": latestBillStatus ? (
                    <MDBBadge color='custom1' className='badge w-100'>{latestBillStatus.billStatusCode.name}</MDBBadge>
                ) : (
                    <MDBBadge color='secondary' light>Not Available</MDBBadge>
                )
            };
        });
    };

    const formatLawsBillStructureForDataExport = (content) => {
        return content.map(item => {
            const latestBillStatus = item.draft.billStatuses.length > 0
                ? item.draft.billStatuses[item.draft.billStatuses.length - 1]
                : null;
    
            return {
                "Bill #": item.billNumber && item.billType ? `${item.billType.code} ${item.billNumber}` : "Not Available",
                "LC #": item.draft.draftNumber ? item.draft.draftNumber : "Not Available",
                "Short Title": item.draft.shortTitle ? item.draft.shortTitle : "Not Available",
                "Primary Sponsor": item.sponsor ? `${item.sponsor.firstName} ${item.sponsor.lastName}` : "Not Available",
                "Status Date": latestBillStatus ? convertISOToReadableTimeFormat(latestBillStatus.timeStamp) : "Not Available",
                "Status": latestBillStatus ? latestBillStatus.billStatusCode.name : "Not Available"
            };
        });
    
    }

    const formatBillStructureForDataExport = (content) => {
        return content.map(item => ({
            "Bill #": item.billNumber && item.billType
                ? `Bill ${item.billNumber}`
                : "Not Available",
            "LC #": item.id.billDraftNumber
                ? item.id.billDraftNumber
                : "Not Available",
            "Short Title": item.shortTitle
                ? item.shortTitle
                : "Not Available",
            "Primary Sponsor": getPrimarySponsor(item.primarySponsorBillRoles)
                ? getPrimarySponsor(item.primarySponsorBillRoles)
                : "Not Available",
            "Status Date": item.lastStatusDate
                ? convertISOToReadableTimeFormat(item.lastStatusDate)
                : convertISOToReadableTimeFormat(item.billActions[0].date),
            "Status": item.lastStatus
                ? item.billActions[0].actionType.description
                : item.billActions[0].actionType.description
        }));
    }

    // Send customized json structure for search API. 
    const filterValidValues = (obj) => {
        const validObj = {};
        Object.entries(obj).forEach(([key, value]) => {
            if (value !== undefined && value !== null && value !== false) {
                if (Array.isArray(value) && value.length > 0) {
                    validObj[key] = value;
                } else if (typeof value === 'string' && value.trim() !== '') {
                    validObj[key] = value;
                } else if (!Array.isArray(value) && typeof value !== 'string') {
                    validObj[key] = value;
                }
            }
        });
        return validObj;
    }

    const getAllSponsors = async () => {
        const { data } = await axios.get( `${process.env.REACT_APP_LAWS_BACKEND_HOST}/legislators/v1/legislators`);
        return data;
    };

    const searchLawsAndSetBills = useCallback(async (resultLimit = 100) => {
        let abortController;
    
        try {
            abortController = new AbortController();
            setAbortControllerQueue((prevQueue) => [...prevQueue, abortController]);
            if (abortControllerQueue.length > 0) {
                abortControllerQueue[0].abort();
            }
            setBills({ columns: tableHeading, rows: [] });
            const { data: { content, totalPages, last } } = await axios.post(
                `${process.env.REACT_APP_LAWS_BACKEND_HOST}/bills/v1/bills/search?limit=${resultLimit}&offset=${offset}`,
                filterValidValues({
                    sessionIds: [legislatureId],
                    subjectIds: subjectCode,
                    chambers: selectedChambers.map(chamber => chamber === 'S' ? 'SENATE' : chamber === 'H' ? 'HOUSE' : ''),
                    fiscalNote: hasFiscalNotes,
                    legalNote: hasLegalNotes,
                    sponsorIds: selectedLegislator,
                    billProgressCategoryIds: selectedProgress,
                    deadlineCodeIds: selectedDeadlines,
                    drafterIds: selectedDrafter
                }),
                { signal: abortController.signal }
            );
            const sponsors = await getAllSponsors();
            const updatedContent = content.map(bill => {
                const sponsor = sponsors.find(s => s.id === bill.sponsorId);
                return {...bill,sponsor: sponsor || null };
            });
            const tableRowData = formatLawsBillStructureForDataTables(updatedContent);
            const formattedData = { columns: tableHeading, rows: tableRowData };
            setLoading(false);
            setBills(formattedData);
            setTotalPages(totalPages);
            setLastPage(last);
        } catch (error) {
            if (axios.isCancel(error)) {
                console.log('Request canceled');
            } else {
                console.error("Error fetching data: ", error);
                throw error;
            }
        } finally {
            // Remove the AbortController from the queue
            setAbortControllerQueue((prevQueue) =>
                prevQueue.filter((controller) => controller !== abortController)
            );
        }
    }, [
        offset,
        legislatureId,
        subjectCode,
        selectedChambers,
        hasFiscalNotes,
        hasLegalNotes,
        selectedProgress,
        selectedLegislator,
        selectedDeadlines,
        selectedDrafter
    ]);
    
    const searchLawsArchiveAndSetBills = useCallback(async (resultLimit = 100) => {

        let abortController;

        try {
            // Create a new AbortController instance
            abortController = new AbortController();
            setAbortControllerQueue((prevQueue) => [...prevQueue, abortController]);

            if (abortControllerQueue.length > 0) {
                abortControllerQueue[0].abort();
            }

            setBills({ columns: tableHeading, rows: [] });
            const { data: { bills: { content, totalPages, last }, counts } } = await axios.post(
                `${process.env.REACT_APP_LAWS_ARCHIVE_BACKEND_HOST}/v1/bills/search?limit=${resultLimit}&offset=${offset}`,
                filterValidValues({
                    sessionId: sessionId,
                    billTypes: selectedBillTypes,
                    legislator: selectedLegislator,
                    subjects: subjectCode,
                    drafter: selectedDrafter,
                    parties: selectedParties,
                    chamber: selectedChambers.includes("S") && selectedChambers.includes("H") ? "" : selectedChambers[0],
                    committeCode: selectedCommittee,
                    currentProgressCategory: selectedProgress,
                    currentStatus: selectedActionTypes,
                    hasAmendment: hasAmendment,
                    hasFiscalNote: hasFiscalNotes,
                    hasLegalNotes: hasLegalNotes,
                    deadlineCategories: selectedDeadlines,
                    text: billSearchText,
                    sortBy: "billNumber",
                    dateFrom: statusStartDate,
                    dateTo: statusEndDate, 
                    sessionLawChapterNumbers: selectedChapters
                }),
                { signal: abortController.signal }
            );

            const tableRowData = formatBillStructureForDataTables(content);
            const formattedData = { columns: tableHeading, rows: tableRowData };

            setLoading(false);
            setBills(formattedData);
            setTotalPages(totalPages);
            setLastPage(last);

            // COUNTS FOR ADVANCED FILTER
            counts.hasOwnProperty("billTypes") ? setBillTypesCount(counts.billTypes) : setBillTypesCount(false);
            counts.hasOwnProperty("chambers") ? setChambersCount(counts.chambers) : setChambersCount(false);
            counts.hasOwnProperty("party") ? setPartyCount(counts.party) : setPartyCount(false);
            counts.hasOwnProperty("deadlineCategories") ? setDealineCategoriesCount(counts.deadlineCategories) : setDealineCategoriesCount(false);
            counts.hasOwnProperty("sessionLawChapterNumbers") ? setChaptersCount(counts.sessionLawChapterNumbers): setChaptersCount(false);
        } catch (error) {
            if (axios.isCancel(error)) {
                console.log('Request canceled');
            } else {
                console.error("Error fetching data: ", error);
                throw error;
            }
        } finally {
            setAbortControllerQueue((prevQueue) =>
                prevQueue.filter((controller) => controller !== abortController)
            );
        }
    }, [
        offset,
        sessionId,
        selectedBillTypes,
        selectedLegislator,
        selectedDrafter,
        selectedParties,
        selectedChambers,
        subjectCode,
        selectedCommittee,
        selectedProgress,
        hasAmendment,
        hasFiscalNotes,
        hasLegalNotes,
        selectedDeadlines,
        selectedActionTypes,
        billSearchText,
        statusStartDate,
        statusEndDate,
        selectedChapters
    ]);

    const searchLawsAndSetExportBills = async () => {
        try {
            const { data: { content, totalPages } } = await axios.post(
                `${process.env.REACT_APP_LAWS_BACKEND_HOST}/bills/v1/bills/search?limit=999999&offset=0`,
                filterValidValues({
                    sessionIds: [legislatureId],
                    subjectIds: subjectCode,
                    chambers: selectedChambers.map(chamber => chamber === 'S' ? 'SENATE' : chamber === 'H' ? 'HOUSE' : ''),
                    fiscalNote: hasFiscalNotes,
                    legalNote: hasLegalNotes,
                    sponsorIds: selectedLegislator,
                    billProgressCategoryIds: selectedProgress,
                    deadlineCodeIds: selectedDeadlines,
                    drafterIds: selectedDrafter
                })
            );
            const sponsors = await getAllSponsors();
            const updatedContent = content.map(bill => {
                const sponsor = sponsors.find(s => s.id === bill.sponsorId);
                return {...bill,sponsor: sponsor || null };
            });
            const exportData = formatLawsBillStructureForDataExport(updatedContent);
            setDataForExport(exportData);
            setExportDataReady(true);
        } catch (error) {
            if (axios.isCancel(error)) {
                console.log('Request canceled');
            } else {
                console.error("Error fetching data: ", error);
                throw error;
            }
        }
    };

    const searchLawsArchiveAndSetExportBills = async () => {
        try {
            const { data: { bills: { content } } } = await axios.post(
                `${process.env.REACT_APP_LAWS_ARCHIVE_BACKEND_HOST}/v1/bills/search?limit=9999&offset=0`,
                filterValidValues({
                    sessionId: sessionId,
                    billTypes: selectedBillTypes,
                    legislator: selectedLegislator,
                    subjects: subjectCode,
                    drafter: selectedDrafter,
                    parties: selectedParties,
                    chamber: selectedChambers.includes("S") && selectedChambers.includes("H") ? "" : selectedChambers[0],
                    committeeCode: selectedCommittee,
                    currentProgressCategory: selectedProgress,
                    currentStatus: selectedActionTypes,
                    hasAmendment: hasAmendment,
                    hasFiscalNote: hasFiscalNotes,
                    deadlineCategories: selectedDeadlines,
                    text: billSearchText,
                    sortBy: "billNumber",
                    dateFrom: statusStartDate,
                    dateTo: statusEndDate
                })
            );
            const exportData = formatBillStructureForDataExport(content);
            setDataForExport(exportData);
            setExportDataReady(true);
        } catch (error) {
            if (axios.isCancel(error)) {
                console.log('Request canceled');
            } else {
                console.error("Error fetching data: ", error);
                throw error;
            }
        }
    };

    const loadExportData = async () => {
        if (sessionId > 20231) {
            await searchLawsAndSetExportBills();
        } else {
            await searchLawsArchiveAndSetExportBills();
        }
    }

    useEffect(() => {
        const delaySearch = setTimeout(() => {
            if (sessionId > 20231) {
                searchLawsAndSetBills(100).catch(error => console.error(error));
            } else {
                searchLawsArchiveAndSetBills(100).catch(error => console.error(error));
            }
        }, 250);
        setLoading(true);
        return () => clearTimeout(delaySearch);
    }, [
        offset,
        sessionId,
        selectedBillTypes,
        selectedLegislator,
        selectedDrafter,
        selectedParties,
        selectedChambers,
        subjectCode,
        selectedCommittee,
        selectedProgress,
        hasAmendment,
        hasFiscalNotes,
        hasLegalNotes,
        selectedDeadlines,
        billSearchText,
        statusStartDate,
        statusEndDate, 
        selectedActionTypes,
        selectedChapters
    ]);

    return (
        <div>
            <Header
                setSideNavToggle={setSideNavToggle}
                sideNavToggle={sideNavToggle}
                dataForExport={dataForExport}
                setBillSearchText={setBillSearchText}
                loadExportData={loadExportData}
                exportDataReady={exportDataReady}
                setExportDataReady={setExportDataReady}
                setOffset={setOffset}
                sessionInfoForHeader={sessionInfoForHeader}
            />
            <MDBDatatable
                data={bills}
                className="bills-datatable"
                hover
                style={{ overflow: 'auto' }}
                entries={100}
                fixedHeader
                pagination={false}
                isLoading={loading}
                striped
                bordered
                noFoundMessage="Nothing matches the search criteria."
                loadingMessage="Loading Bills..."
                sortField='Bill #'
                sortdesc='asc'
                maxHeight='100vh'
            />
            <MDBFooter className='text-center text-lg-end text-muted mt-2 pb-2'>
                <Pagination offsetPage={offset} setOffsetPage={setOffset} totalPages={totalPages} lastPage={lastPage} />
            </MDBFooter>
        </div>
    );
}

export default BillsTable;