import React, { useEffect, useState } from 'react';
import axios from 'axios';
import { MDBSpinner, MDBRadio, MDBSelect, MDBSideNavItem, MDBSideNavLink, MDBSideNavCollapse } from 'mdb-react-ui-kit';

const Committee = ({ sessionId, selectedCommittee, setSelectedCommitte, setOffset, legislatureId }) => {
    const [collapse, setCollapse] = useState(false);
    const [committee, setCommittee] = useState([]);
    const [isLoading, setIsLoading] = useState(true);
    const [selectedOption, setSelectedOption] = useState('--Select Committee--');

    const fetchData = async () => {
        setIsLoading(true);
        try {
            const requests = [
                axios.get(`${process.env.REACT_APP_LAWS_ARCHIVE_BACKEND_HOST}/v1/committees/findBySession/${sessionId}`)
            ];
            if (sessionId > 20231) {
                requests.unshift(
                    axios.get(`${process.env.REACT_APP_LAWS_BACKEND_HOST}/committees/v1/standingCommittees`),
                    axios.get(`${process.env.REACT_APP_LAWS_BACKEND_HOST}/committees/v1/nonStandingCommittees/findByLegislatureId?legislatureId=${legislatureId}`)
                );
            }

            const [archiveCommittees, lawsStandingCommittees, lawsNonStandingCommittees] = await Promise.all(requests);

            const filterICCommittees = (data) => data.filter(item => item.committeeTypeName !== 'IC');

            const archiveCommitteesData = filterICCommittees(archiveCommittees?.data || []).map(item => ({ value: item.id.committeeCode, text: item.committeeName }));
            const lawsCommittieesData = filterICCommittees(lawsStandingCommittees?.data || []).map(item => ({ value: item.code, text: item.name }));
            const nonStandingCommitteesData = filterICCommittees(lawsNonStandingCommittees?.data || []).map(item => ({ value: item.committeeDetails.committeeCode.code, text: item.committeeDetails.committeeCode.name }));

            const allCommittees = [...lawsCommittieesData, ...nonStandingCommitteesData, ...archiveCommitteesData];
            const committeeWithSelectOption = [{ value: '0', text: '-- Select Committee --', disabled: true }, ...allCommittees];

            setCommittee(committeeWithSelectOption);
            setIsLoading(false);
        } catch (error) {
            console.error('Error fetching data: ', error);
            setIsLoading(false);
        }
    };

    const handleRadioButtonChange = (event) => {
        setSelectedOption(event.target.value);
    };

    useEffect(() => {
        fetchData();
    }, [sessionId]);

    useEffect(() => {
        if (selectedCommittee === '') setSelectedOption("0");
    }, [selectedCommittee]);

    const handleCommitteeChange = (value) => {
        if (value === "0") {
            setSelectedOption("0");
            setSelectedCommitte("");
        } else {
            setSelectedOption(value);
            setSelectedCommitte(value);
        }
        setOffset(0);
    };

    return (
        <MDBSideNavItem>
            <MDBSideNavLink className='accordion-button' icon='angle-down' shouldBeExpanded={collapse} onClick={() => setCollapse(!collapse)}>
                Committee
            </MDBSideNavLink>
            <MDBSideNavCollapse show={collapse}>
                <li className='p-3'>
                    {isLoading ? <span className='mx-4'><MDBSpinner size='sm' role='status' tag='span' className='me-2' /> Loading...</span> :
                        <div>
                            <MDBSelect
                                value={selectedOption}
                                data={committee}
                                clearBtn
                                onValueChange={({ value }) => { handleCommitteeChange(value) }}
                                id="committeeFilter"
                                search
                                data-mdb-option-height="100"
                            />
                            {/* <div className='form-text'>
                                <MDBRadio label="Requester"
                                    checked={selectedOption === 'Requester'}
                                    onChange={handleRadioButtonChange}
                                    name="committee"
                                    value="Requester"
                                    inline
                                />
                                <MDBRadio label="Referred"
                                    checked={selectedOption === 'Referred'}
                                    onChange={handleRadioButtonChange}
                                    name="committee"
                                    value="Referred"
                                    inline
                                />
                            </div> */}
                        </div>
                    }
                </li>
            </MDBSideNavCollapse>
        </MDBSideNavItem>
    );
}

export default Committee;