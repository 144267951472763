import React, { useEffect, useState } from 'react';
import { MDBRadio, MDBSelect, MDBSideNavCollapse, MDBSideNavItem, MDBSideNavLink, MDBSpinner } from 'mdb-react-ui-kit';
import axios from 'axios';


// remove this code after logi doc loads all the sessions document *** THIS FUNCTION IS TEMPREORY ***
const removeSessionsPriorTo2009 = (sessions) => {
    return sessions.filter(item => {
        const year = parseInt(item.text.split(' ')[0]);
        return year >= 1999 && year <= 2023;
    });
}

const removeSessionsPriorTo2015 = (sessions) => {
    return sessions.filter(item => {
        const year = parseInt(item.text.split(' ')[0]);
        return year >= 2015;
    });
}

const Session = ({ sessionId, setSessionId, setOffset, setLegislatureId, sessionInfoForHeader, setSessionInfoForHeader }) => {
    const [collapse, setCollapse] = useState(false);
    const [filteredSessionHolder, setFilteredSessionHolder] = useState([]);
    const [isLoading, setIsLoading] = useState(true);
    const [lawsSession, setLawsSession] = useState([]);

    const fetchSessions = async () => {
        try {
            const [archiveResponse, lawsResponse] = await Promise.all([
                axios.get(`${process.env.REACT_APP_LAWS_ARCHIVE_BACKEND_HOST}/v1/sessions`),
                axios.get(`${process.env.REACT_APP_LAWS_BACKEND_HOST}/legislators/v1/sessions`)
            ]);
            const archiveSessions = createSelectDataStructureOfLawsArchiveSession(archiveResponse.data);
            const lawsSessions = createSelectDataStructureOfLawsSession(lawsResponse.data);
            const combinedSessions = [...lawsSessions, ...archiveSessions];
            const uniqueSessions = removeDuplicates(combinedSessions);
            let dataSortedInDescendingOrder =  alignDataDescending(uniqueSessions);
            // this condtion can be removed once the years needs to filtered out
            if(process.env.REACT_APP_ENV == 'PRODUCTION') dataSortedInDescendingOrder = removeSessionsPriorTo2009(dataSortedInDescendingOrder);
            else dataSortedInDescendingOrder = removeSessionsPriorTo2015(dataSortedInDescendingOrder);
            setFilteredSessionHolder(dataSortedInDescendingOrder);
            setIsLoading(false);
            setLawsSession(lawsResponse);
        } catch (error) {
            console.error('Error fetching data: ', error);
        }
    };

    const createSelectDataStructureOfLawsArchiveSession = (sessions) => {
        return sessions.map((item) => ({
            text: `${item.sessionYear.toString()} (${item.sessionTypeDesc})`,
            value: item.sessionId,
        }));
    };

    const createSelectDataStructureOfLawsSession = (sessions) => {
        return sessions.map(item => {
            if (item.startDate) {
                return {
                    text: `${new Date(item.startDate).getFullYear().toString()} (${item.type.charAt(0) + item.type.slice(1).toLowerCase()})`,
                    value: Number(item.ordinals)
                };
            }
            return null;
        }).filter(Boolean);
    };

    const removeDuplicates = (arr) => {
        const seenValues = new Set();
        return arr.filter(item => item && !seenValues.has(item.text) && seenValues.add(item.text));
    };


    const alignDataDescending = (data) => {
        return data.sort((a, b) => b.value - a.value);
    };

    useEffect(() => {
        try {
            setSessionId(sessionId);
            fetchSessions();
        } catch (error) {
            console.log(error);
        }
    }, [sessionId]);

    const getLegislatureId = (ordinal) => {
        const foundItem = lawsSession.data.find(item => {return item.ordinals == ordinal});
        return foundItem ? foundItem.id : false;
    };

    const handleSessionChange = (value) => {
        setSessionId(value); 
        setLegislatureId(getLegislatureId(value));
        const sessionObj =  filteredSessionHolder.find( item => item.value === value);
        setSessionInfoForHeader(sessionObj.text.toString().replace(/[()]/g, ''));
        setOffset(0); 
    };
   
    return (
        <MDBSideNavItem>
            <MDBSideNavLink className='accordion-button' icon='angle-down' shouldBeExpanded={collapse} onClick={() => setCollapse(!collapse)}>
                Session
            </MDBSideNavLink>
            <MDBSideNavCollapse show={collapse}>
                <li className='p-3'>
                    {isLoading ? <p><MDBSpinner size='sm' role='status' tag='span' className='me-2' /> Loading...</p> :
                        <MDBSelect
                            data={filteredSessionHolder}
                            search
                            id='sessionYearSelect'
                            value={sessionId}
                            onValueChange={({value}) => { handleSessionChange(value) }}
                        />
                    }
                </li>
            </MDBSideNavCollapse>
        </MDBSideNavItem>
    );
};


export default Session;


