import { MDBIcon } from 'mdb-react-ui-kit'
import React from 'react'

const FilterHeader = ({setSideNavToggle}) => {
  return (
    <div className='d-flex justify-content-between align-items-middle my-3'>
      <h4 className='m-1'><MDBIcon fas icon="filter" /> <b>Filters</b></h4>
        <a href="#" className="m-1 btn-close" onClick={ () => setSideNavToggle(false)}></a>
    </div>
  )
}

export default FilterHeader