import { MDBBtn, MDBNavbar, MDBContainer, MDBDropdown, MDBDropdownItem, MDBDropdownMenu, MDBDropdownToggle, MDBIcon, MDBPopover, MDBPopoverBody, MDBPopoverHeader, MDBInputGroup } from 'mdb-react-ui-kit'
import React, { useEffect, useState } from 'react'
import { exportData } from '../../../helper/export';

const getCurrentDate = () => {
  const currentDate = new Date();
  const year = currentDate.getFullYear();
  const month = String(currentDate.getMonth() + 1).padStart(2, '0');
  const day = String(currentDate.getDate()).padStart(2, '0');
  return `${year}-${month}-${day}`;
}

const Header = ({ setSideNavToggle, sideNavToggle, dataForExport, setBillSearchText, loadExportData, exportDataReady, setExportDataReady, setOffset, sessionInfoForHeader }) => {

    const [ isDownloading, setIsDownloading ] = useState(false); 
    const [ isCsv, setIsCsv ] = useState(false); 
    const [ isPdf, setIsPdf ] = useState(false);
    
    const handleCsvClick = async () => {
        setIsDownloading(true);
        setIsCsv(true);
        await loadExportData();
    };

    const handlePdfClick = async () => {
        setIsDownloading(true);
        setIsPdf(true);
        await loadExportData();
    };

    useEffect( () => {
        if(isCsv && exportDataReady) {
            exportData(dataForExport, `Filtered_Bills_${getCurrentDate()}`, 'csv')
            setIsDownloading(false);
            setExportDataReady(false);
            setIsCsv(false);
        }
        if(isPdf && exportDataReady){
            exportData(dataForExport, `Filtered_Bills_${getCurrentDate()}`, 'pdf');
            setIsDownloading(false);
            setExportDataReady(false);
            setIsPdf(false);
        }
    }, [exportDataReady]);

    return (
        <div className='row py-3 g-0'>
            <div className='col-lg text-center text-md-start mb-3 mb-md-0'>
                <h6 className="lead mb-0">{sessionInfoForHeader} Session</h6>
                <h2 className='title mb-3 mb-lg-0 me-lg-auto mx-auto text-dark'>Bill Explorer</h2>
            </div>
            <div className='col-lg'>
                <div className='d-flex justify-content-end align-items-center mt-0 mt-lg-4'>
                    <input style={{ width: '100%' }} className='form-control me-1' onChange={( (e) => {setBillSearchText(e.target.value); setOffset(0)})} placeholder='Search Bill/LC #, Short Title, Sponsor (example: HB 100, LC1234)' type="text" />
                </div>
            </div>
            <div className='col-auto mt-lg-0 mx-auto mt-3'>
                <div className='d-flex justify-content-end'>
                    <MDBPopover tag='a' href='#' btnChildren={<MDBIcon fas icon="info-circle" />} dismiss placement='top'>
                        <MDBPopoverHeader>Quick Search</MDBPopoverHeader>
                        <MDBPopoverBody>Using the search bar will search text in the displayed columns: Bill #, LC # Short Title, and Primary Sponsor.  Use the Filters for more in-depth searching.</MDBPopoverBody>
                    </MDBPopover>
                    <a href="https://leg.mt.gov/bill-info/" target="_blank" className="ms-1"><MDBIcon fas icon="question-circle" /></a>
                </div>
                <div className='d-flex justify-content-end align-items-center'>                    
                    <MDBBtn onClick={() => { setSideNavToggle(!sideNavToggle) }} className='shadow-none ms-1'><MDBIcon fas icon="filter" /> Filters</MDBBtn>
                    <MDBDropdown>
                        <MDBDropdownToggle className='shadow-none ms-1'><MDBIcon fas icon="cloud-download-alt" /> Export</MDBDropdownToggle>
                        <MDBDropdownMenu>
                            <MDBDropdownItem link onClick={handleCsvClick}>Excel/CSV { isDownloading && isCsv ? " (Downloading ...)": '' }</MDBDropdownItem>
                            <MDBDropdownItem link onClick={handlePdfClick}>Print/PDF { isDownloading && isPdf ? " (Downloading ...)": '' }</MDBDropdownItem>
                        </MDBDropdownMenu>
                    </MDBDropdown>
                </div>
            </div>
        </div>
    )
}

export default Header;