import React, { useEffect, useState } from 'react';

import jsPDF from 'jspdf';
import 'jspdf-autotable';

export const exportData = (data, fileName, format) => {
  if (format === 'csv') {
    if (!data || data.length === 0) {
      console.error("No data available to convert.");
      return;
    }
    const keys = Object.keys(data[0]);
    const csvRows = [];
    csvRows.push(keys.join(','));
    data.forEach(row => {
      const values = keys.map(key => {
        const escapeValue = ('' + row[key]).replace(/"/g, '\\"');
        return `"${escapeValue}"`;
      });
      csvRows.push(values.join(','));
    });
    const csvString = csvRows.join('\n');
    const blob = new Blob([csvString], { type: 'text/csv' });
    const url = window.URL.createObjectURL(blob);
    const a = document.createElement('a');
    a.setAttribute('href', url);
    a.setAttribute('download', `${fileName}.csv`);
    a.click();
  } else if (format === 'pdf') {
    const doc = new jsPDF();
    doc.text('Exported Data', 10, 10);
    const headers = Object.keys(data[0]);
    const tableData = data.map(row => headers.map(header => row[header]));
    doc.autoTable({ head: [headers], body: tableData });
    doc.save(`${fileName}.pdf`);
    return null;
  }
  else if (format === 'print') {
    window.print();
  }
  else {
    console.error('Unsupported export format');
    return null;
  }
};