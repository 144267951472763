import axios from 'axios';
import { MDBTabsPane } from 'mdb-react-ui-kit';
import React, { useEffect, useState } from 'react';

const API_BASE_URL = process.env.REACT_APP_LAWS_BACKEND_HOST;

const createParams = (bill, ordinal) => {
  const params = new URLSearchParams();
  params.append('legislatureOrdinal', `${ordinal}`);
  params.append('sessionOrdinal', `${bill.id.sessionId}`);
  params.append('billType', `${bill.billType}`);
  params.append('billNumber', `${bill.billNumber}`);
  return params;
};

const fetchPdfUrl = async (document) => {
  try {
    const pdfUrlResponse = await axios.post(`${API_BASE_URL}/docs/v1/documents/shortPdfUrl?documentId=${document.id}`);
    return { ...document, pdfUrl: pdfUrlResponse.data };
  } catch (error) {
    console.error(`Error fetching PDF URL for document ${document.id}: `, error);
    return document;
  }
};

const fetchBillVersions = async (bill, ordinal) => {
  const params = createParams(bill, ordinal);
  try {
    const response = await axios.get(`${API_BASE_URL}/docs/v1/documents/getBillVersions?${params.toString()}`);
    return await Promise.all(response.data.map(fetchPdfUrl));
  } catch (error) {
    console.error('Error fetching bill versions: ', error);
    return [];
  }
};

const fetchBillOther = async (bill, ordinal) => {
  const params = createParams(bill, ordinal);
  try {
    const response = await axios.get(`${API_BASE_URL}/docs/v1/documents/getBillOther?${params.toString()}`);
    return await Promise.all(response.data.map(fetchPdfUrl));
  } catch (error) {
    console.error('Error fetching other bill data: ', error);
    return [];
  }
};

const fetchAllBillData = async (bill, ordinal) => {
  const [billVersions, billOther] = await Promise.all([
    fetchBillVersions(bill, ordinal),
    fetchBillOther(bill, ordinal)
  ]);
  return { billVersions, billOther };
};

const DocumentsAndLinks = ({ active, bill, ordinal }) => {
  const [billVersions, setBillVersions] = useState([]);
  const [billOther, setBillOther] = useState([]);
  const [isLoading, setIsLoading] = useState(true);
  const [error, setError] = useState(null);

  useEffect(() => {
    const fetchAllDataAndUpdate = async () => {
      setIsLoading(true);
      setError(null);
      try {
        const { billVersions, billOther } = await fetchAllBillData(bill, ordinal);
        setBillVersions(billVersions);
        setBillOther(billOther);
      } catch (err) {
        setError(err);
        console.error('Error in fetchAllDataAndUpdate:', err);
      } finally {
        setIsLoading(false);
      }
    };

    fetchAllDataAndUpdate();
  }, [bill, ordinal]);

  if (!active) return null;

  return (
      <MDBTabsPane className="p-3" show={active}>
        {isLoading && <div>Loading...</div>}
        {error && <div>Error: {error.message}</div>}
        {!isLoading && !error && (
            <>
              <h4>Previous Versions</h4>
              {billVersions.length > 0 ? (
                  billVersions.slice().reverse().map((version, index) => (
                      <div key={index}>
                        {version.pdfUrl ? (
                            <a href={version.pdfUrl} target="_blank" rel="noopener noreferrer">{version.fileName}</a>
                        ) : (
                            version.fileName
                        )}
                      </div>
                  ))
              ) : (
                  <div>None</div>
              )}

              <h4>Other</h4>
              {billOther.length > 0 ? (
                  billOther.map((other, index) => (
                      <div key={index}>
                        {other.pdfUrl ? (
                            <a href={other.pdfUrl} target="_blank" rel="noopener noreferrer">{other.fileName}</a>
                        ) : (
                            other.fileName
                        )}
                      </div>
                  ))
              ) : (
                  <div>None</div>
              )}
            </>
        )}
      </MDBTabsPane>
  );
};

export default DocumentsAndLinks;