import React from 'react';
import './ArrowProgressBar.css';
import { MDBIcon } from 'mdb-react-ui-kit';

const renderStep = (step, condition, label, date) => {
  const showDate = step >= condition && date;
  return (
    <div className={`step ${step >= condition ? 'current' : ''}`}>
      {step >= condition && <MDBIcon fas className='mx-1' icon="check" />}
      {label}
      {showDate && ` (${date})`}
    </div>
  );
};

const ArrowProgressBar = ({ step, billType, dates }) => {
  const steps = billType === 'H' ? [
    { condition: 1, label: 'Introduced', dateIndex: 5 },
    { condition: 2, label: 'Passed (H) Committee', dateIndex: 4 },
    { condition: 3, label: 'Passed House', dateIndex: 3 },
    { condition: 4, label: 'Passed (S) Committee', dateIndex: 2 },
    { condition: 5, label: 'Passed Senate', dateIndex: 1 },
    { condition: 6, label: 'To Governor', dateIndex: 0 }
  ] : [
    { condition: 1, label: 'Introduced', dateIndex: 6 },
    { condition: 2, label: 'Passed (S) Committee', dateIndex: 5 },
    { condition: 3, label: 'Passed Senate', dateIndex: 4 },
    { condition: 4, label: 'Passed (H) Committee', dateIndex: 3 },
    { condition: 5, label: 'Passed House', dateIndex: 2 },
    { condition: 6, label: 'To Governor', dateIndex: 1 }
  ];

  return (
    <div className="arrow-steps-container">
      <div className="arrow-steps">
        {steps.map(({ condition, label, dateIndex }) => (
          renderStep(step, condition, label, dates[dateIndex] || "Date Not available")
        ))}
      </div>
    </div>
  );
};

export default ArrowProgressBar;


