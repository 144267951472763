import { useParams, useLocation } from 'react-router-dom';
import {
    MDBModal,
    MDBModalDialog,
    MDBModalContent,
    MDBModalHeader,
    MDBModalTitle,
    MDBModalBody,
    MDBIcon, MDBContainer
} from 'mdb-react-ui-kit';
import Tabs from "./Tabs";
import React, { useEffect, useState } from 'react';
import axios from 'axios';
import AppBuildVersion from "../AppBuildVersion/AppBuildVersion";

const LCBillDetails = () => {
    const { sessionId, id } = useParams();
    const location = useLocation();
    const [bill, setBill] = useState({});
    const [ ordinal, setOrdinal ] = useState(0);
    const query = new URLSearchParams(location.search);
    console.log({query});
    const openTab = query.get('open_tab') || 'Summary';
    useEffect(() => {
        async function fetchDataAndSetBills() {
            try {
                const { data: billData } = await axios.get(`${process.env.REACT_APP_LAWS_ARCHIVE_BACKEND_HOST}/v1/bills/${sessionId}/${id}`);
                const { data: sessionData } = await axios.get(`${process.env.REACT_APP_LAWS_ARCHIVE_BACKEND_HOST}/v1/sessions`);
                const sessionFound = sessionData.find(item => item.sessionId == sessionId);
                const ordinal = sessionFound ? sessionFound.ordinal : 0;
                setBill(billData);
                setOrdinal(ordinal);
            } catch (error) {
                console.error("Error fetching data: ", error);
                throw error;
            }
        }

        fetchDataAndSetBills();
    }, [sessionId, id]);

    if (Object.keys(bill).length === 0) {
        return null; 
    }

    return (
        <>
            <MDBModal show={true}>
                <MDBModalDialog size='fullscreen'>
                    <MDBModalContent>
                        <MDBModalHeader>
                            <MDBModalTitle>
                                {id} { bill.billType ? "" : bill.billType} {  bill.billType ? "" : bill.number}: {bill.shortTitle}
                            </MDBModalTitle>
                        </MDBModalHeader>
                        <MDBModalBody style={{ background: `#C5CFCE url(${process.env.PUBLIC_URL + '/bkgd-geometric.png'}) repeat 0 0 fixed`}}>
                            <Tabs billDetails={bill} ordinal={ordinal} defaultTab={openTab}/>
                            <AppBuildVersion />
                        </MDBModalBody>
                    </MDBModalContent>
                </MDBModalDialog>
            </MDBModal>
        </>
    );
};

export default LCBillDetails;
