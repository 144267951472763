import React, { useEffect, useState } from 'react';
import { MDBCheckbox, MDBSideNavCollapse, MDBSideNavItem, MDBSideNavLink, MDBSpinner } from 'mdb-react-ui-kit';
import axios from 'axios';

const Party = ({ sessionId, setOffset, selectedParties, setSelectedParties, partyCount, setSelectedLegislator }) => {
    const [collapse, setCollapse] = useState(false);
    const [parties, setParties] = useState([]);
    const [legislators, setLegislators] = useState([]);
    const [isLoading, setIsLoading] = useState(false);

    useEffect(() => {
        const fetchData = async () => {
            setIsLoading(true);
            let mappedParties = [];

            try {
                if (sessionId > 20231) {
                    let partyResponse = await axios.get(`${process.env.REACT_APP_LAWS_BACKEND_HOST}/legislators/v1/politicalParties`);
                    mappedParties = partyResponse.data.map(party => ({
                        partyCode: party.code,
                        partyName: party.name
                    }));

                    let legislatorResponse = await axios.get(`${process.env.REACT_APP_LAWS_BACKEND_HOST}/legislators/v1/legislators`);
                    setLegislators(legislatorResponse.data);
                } else {
                    let partyResponse = await axios.get(`${process.env.REACT_APP_LAWS_ARCHIVE_BACKEND_HOST}/v1/parties`);
                    mappedParties = partyResponse.data.map(party => ({
                        partyCode: party.partyCode,
                        partyName: party.partyName
                    }));
                }
            } catch (error) {
                console.error('Error fetching data: ', error);
            }

            setParties(mappedParties);
            setIsLoading(false);
        };

        fetchData();
    }, [sessionId]);

    const handleCheckboxChange = (partyCode) => {
        const isSelected = selectedParties.includes(partyCode);
        let updatedSelectedParties;

        if (isSelected) {
            updatedSelectedParties = selectedParties.filter(code => code !== partyCode);
            setSelectedParties(updatedSelectedParties);
        } else {
            updatedSelectedParties = [...selectedParties, partyCode];
            setSelectedParties(updatedSelectedParties);
        }

        setOffset(0);

        if (sessionId > 20231) {
            const filteredLegislators = legislators
                .filter(legislator => legislator.politicalParty.code === partyCode)
                .map(legislator => legislator.id);
            setSelectedLegislator(prevSelectedLegislator => {
                let updatedLegislatorIds;
                if (isSelected) {
                    updatedLegislatorIds = prevSelectedLegislator.filter(id => !filteredLegislators.includes(id));
                } else {
                    updatedLegislatorIds = [...prevSelectedLegislator, ...filteredLegislators];
                }
                return [...new Set(updatedLegislatorIds)];
            });
        }
    };

    return (
        <MDBSideNavItem>
            <MDBSideNavLink className='accordion-button' icon='angle-down' shouldBeExpanded={collapse} onClick={() => setCollapse(!collapse)}>
                Party
            </MDBSideNavLink>
            <MDBSideNavCollapse show={collapse}>
                <li className='p-3'>
                    {
                        isLoading ? (
                            <div className="d-flex justify-content-center">
                                <MDBSpinner role="status">
                                    <span className="visually-hidden">Loading...</span>
                                </MDBSpinner>
                            </div>
                        ) : (
                            parties.map((party, index) => {
                                return (
                                    <div key={index} className="d-flex justify-content-between align-items-start">
                                        <MDBCheckbox
                                            name='houseChamber'
                                            value={party.partyCode}
                                            label={party.partyName}
                                            className="float-start legmt-checkbox"
                                            onChange={() => handleCheckboxChange(party.partyCode)}
                                            checked={selectedParties.includes(party.partyCode)}
                                        />
                                    </div>
                                );
                            })
                        )
                    }
                </li>
            </MDBSideNavCollapse>
        </MDBSideNavItem>
    );
};

export default Party;

