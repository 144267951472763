import React, { useEffect, useState } from 'react';
import { MDBTabsPane, MDBRow, MDBCol, MDBTable, MDBTableBody, MDBContainer } from "mdb-react-ui-kit";
import './css/summary.css';
import { format, parseISO } from 'date-fns';
import ArrowProgressBar from '../ArrowProgressBar/ArrowProgressBar';
import axios from 'axios';

const convertISOToReadableTimeFormat = (date) => format(parseISO(date), "MM/dd/yy");

const convertTimestamp = (timestamp) => {
  try {
    if (!timestamp) return '';
    const datePart = timestamp.split(' ')[0];
    const [year, month, day] = datePart.split('-');
    const formattedMonth = month.padStart(2, '0');
    const formattedDay = day.padStart(2, '0');
    return `${formattedMonth}/${formattedDay}/${year}`;
  } catch (error) {
    return timestamp;
  }
};

const getMatchingNumber = (status, billType) => {
  const house = ['020', '040', '060', '090', '110', '180']; // HOUSE FLOW
  const senate = ['020', '090', '110', '040', '060', '180']; // SENATE FLOW
  let steps = 0;
  let dates = [];

  status.forEach((statusItem) => {
    if (statusItem.actionType.progressCategory) {
      const code = statusItem.actionType.progressCategory.code.toString();
      if (billType === 'H' && house.includes(code)) {
        const index = house.indexOf(code) + 1;
        steps = Math.max(steps, index);
        dates.push(convertISOToReadableTimeFormat(statusItem.date));
      } else if (billType === 'S' && senate.includes(code)) {
        const index = senate.indexOf(code) + 1;
        steps = Math.max(steps, index);
        dates.push(convertISOToReadableTimeFormat(statusItem.date));
      }
    }
  });

  return { steps, dates };
};

const Summary = ({ active, bill, onClickOpenTab, ordinal }) => {
  const params = new URLSearchParams();
  params.append('legislatureOrdinal', `${ordinal}`);
  params.append('sessionOrdinal', `${bill.id.sessionId}`);
  params.append('billType', `${bill.billType}`);
  params.append('billNumber', `${bill.billNumber}`);

  const primarySponsor = bill.primarySponsorBillRoles[0];
  const BillTypeChar = bill.billType ? bill.billType.charAt(0).toUpperCase() : 'H';
  const { steps, dates } = getMatchingNumber(bill.billActions, BillTypeChar);
  const billAmendmentsAvailableinBillActions = bill.billActions.find((item) => typeof item.amendmentFileName === "string");

  const [billType, setBillType] = useState(BillTypeChar);
  const [step, setStep] = useState(steps);
  const [legislatorProfilePic, setLegislatorProfilePic] = useState(false);
  const [legislatorProfileLink, setLegislatorProfileLink] = useState(false);
  const [isLoading, setIsLoading] = useState(true);
  const [billSection, setBillSection] = useState([]);
  const [chapterPdfUrl, setChapterPdfUrl] = useState();

  const sortDateWithAscendingStatus = bill.billActions.sort((a, b) => new Date(b.date) - new Date(a.date));

  useEffect(() => {
    async function fetchProfile() {
      try {
        if (primarySponsor && primarySponsor.hasOwnProperty("lawEntity")) {
          const { data } = await axios.get(`https://press.legmt.gov/index.php/wp-json/v1/legislator?lawEntId=${primarySponsor?.lawEntity?.entityIdSequence}`);
          setLegislatorProfilePic(data[0]?.profilePic);
          setLegislatorProfileLink(data[0]?.oldProfileURL);
        } else {
          setLegislatorProfilePic(false);
        }
      } catch (error) {
        setLegislatorProfilePic(false);
        console.log({ error });
      }
    }
    fetchProfile();
  }, [primarySponsor]);

  useEffect(() => {
    async function fetchBillSection() {
      try {
        const response = await axios.get(`${process.env.REACT_APP_LAWS_ARCHIVE_BACKEND_HOST}/v1/billSections/${bill.id.sessionId}/${bill.id.billDraftNumber}`);
        setBillSection(response.data);
      } catch (error) {
        console.error("Error fetching bill section: ", error);
      }
    }

    function padNumber(number, prefix, suffix, length) {
      return `${prefix}${number.toString().padStart(length, '0')}.${suffix}`;
    }

    async function fetchSingleChapter() {
      try {
        if (!ordinal || !bill?.id?.sessionId) {
          throw new Error("Missing required parameters: ordinal or sessionId");
        }

        const encodedRootFolder = encodeURIComponent(`${ordinal}/${bill.id.sessionId}/Bills/Chapters`);
        const folderResponse = await axios.get(
          `${process.env.REACT_APP_DOCS_BACKEND_HOST}/v1/folders/findByPath?path=${encodedRootFolder}`
        );

        if (!folderResponse.data || !folderResponse.data.id) {
          throw new Error("Invalid folder id supplied");
        }

        const chapterDirectoryResponse = folderResponse.data;

        const archiveChapterDoc = padNumber(
          bill.sessionLawChapterNumber ? bill.sessionLawChapterNumber.toString() : "",
          "CH",
          "pdf",
          4
        );

        const documentResponse = await axios.get(
          `${process.env.REACT_APP_LAWS_BACKEND_HOST}/docs/v1/documents/listByFolderFileName?folderId=${chapterDirectoryResponse.id}&fileName=${archiveChapterDoc}`
        );

        if (!documentResponse.data) {
          throw new Error("No data received from document response");
        }

        return documentResponse.data;

      } catch (error) {
        if (axios.isAxiosError(error)) {
          console.error("Axios error fetching chapters:", error.response?.data || error.message);
        } else {
          console.error("Error fetching chapters:", error.message);
        }
        return null;
      }
    }

    async function fetchChapterPdfUrl(chapterId) {
      try {
        const response = await axios.post(`${process.env.REACT_APP_LAWS_BACKEND_HOST}/docs/v1/documents/shortPdfUrl?documentId=${chapterId}`);
        setChapterPdfUrl(response.data);
      } catch (error) {
        console.error("Error fetching chapter PDF URL: ", error);
      }
    }

    async function fetchAll() {
      setIsLoading(true);
      try {
        await fetchBillSection();
        const chapterPdfDoc = await fetchSingleChapter();
        if (chapterPdfDoc) {
          await fetchChapterPdfUrl(chapterPdfDoc[0].id);
        }
      } catch (error) {
        console.error("Error fetching all data: ", error);
      } finally {
        setIsLoading(false);
      }
    }
    fetchAll();
  }, []);

  return (
    <MDBTabsPane show={active}>
      <MDBContainer fluid>
        <ArrowProgressBar step={step} billType={billType} dates={dates} />
        <MDBRow className='g-2'>
          <MDBCol show={active} md="3" className="pe-0 order-last order-md-first">
            {isLoading && legislatorProfilePic ? (
              <div className="text-center m-5">
                <div className="spinner-border" style={{ width: '2rem', height: '2rem' }} role="status">
                  <span className="visually-hidden">Loading...</span>
                </div>
              </div>
            ) : (
              <div className="text-center mt-2">
                <figure>
                  <img
                    src={legislatorProfilePic || `${process.env.PUBLIC_URL}/primary-sponsor-not-found.png`}
                    className="figure-img img-fluid rounded-circle mb-3"
                    alt={"Legislator Portrait"}
                  />
                  {primarySponsor && primarySponsor.hasOwnProperty("lawEntity") ? (
                    <figcaption className="figure-caption">
                      <h5 className="mb-0">
                        <a target="_blank" rel="noopener noreferrer" href={legislatorProfileLink}>
                          {
                            primarySponsor.hasOwnProperty("lawEntity") ?
                              primarySponsor.lawEntity.lastName + ", " + primarySponsor.lawEntity.firstName :
                              "Primary Sponsor not available"
                          }
                        </a>
                      </h5>
                      ({primarySponsor.legislator.politicalParty?.partyCode ? primarySponsor.legislator.politicalParty.partyCode : "Party code not available"}){" "}
                      {billType ? (billType.startsWith('H') ? "HD" : "SD") : ""}{" "}
                      {primarySponsor.legislator.districtNumber ? primarySponsor.legislator.districtNumber : "District number not available"}
                    </figcaption>
                  ) : (
                    <figcaption className="figure-caption">Primary sponsor unavailable</figcaption>
                  )}
                </figure>
              </div>
            )}
          </MDBCol>
          <MDBCol md="9" className="ps-0">
            <MDBTable className="billTable">
              <MDBTableBody>
                <tr>
                  <td scope="row" width="225">Draft #</td>
                  <td>{bill.id.billDraftNumber == null ? "No Draft number" : bill.id.billDraftNumber}</td>
                </tr>
                <tr>
                  <td scope="row">Amendment(s)</td>
                  <td>{billAmendmentsAvailableinBillActions ? <a className="text-decoration-underline" onClick={(e) => { e.preventDefault(); onClickOpenTab('Amendment') }} href="#">Yes</a> : "No"}</td>
                </tr>
                <tr>
                  <td scope="row">Chapter #</td>
                  <td>
                    {chapterPdfUrl ? (
                      <a
                        href={chapterPdfUrl}
                        className="text-decoration-underline"
                        target="_blank"
                        rel="noopener noreferrer"
                      >
                        {bill.sessionLawChapterNumber}
                      </a>
                    ) : (
                      bill.sessionLawChapterNumber || "None"
                    )}
                  </td>
                </tr>
                <tr>
                  <td scope="row">Current Status</td>
                  <td>{sortDateWithAscendingStatus[0].actionType.description ? sortDateWithAscendingStatus[0].actionType.description : "Status not available"}</td>
                </tr>
                <tr>
                  <td scope="row">Co-Sponsor(s)</td>
                  <td>{bill.coSponsors == null ? "None" : "Mark"}</td>
                </tr>
                <tr>
                  <td scope="row">Drafter</td>
                  <td>
                    {bill.drafters.length > 0 ? bill.drafters.map((drafter, index) => (
                      <span key={index}>{drafter.lawEntity?.lastName}, {drafter.lawEntity?.firstName}</span>
                    )) : ""}
                  </td>
                </tr>
                {bill.requesters.length > 0 && (
                  <tr>
                    <td scope="row">Requester</td>
                    <td>
                    {bill.requesters.map((requester, index) => (
                      <span key={index}>
                        {requester.lawEntity && 
                        (requester.lawEntity.lastName || requester.lawEntity.firstName) ? 
                          `${requester.lawEntity.lastName || ''}${requester.lawEntity.lastName && requester.lawEntity.firstName ? ', ' : ''}${requester.lawEntity.firstName || ''}` 
                          : ''}
                      </span>
                    ))}
                    </td>
                  </tr>
                )}
                {bill.requestOf.length > 0 && (
                  <tr>
                    <td scope="row">By Request Of</td>
                    <td>
                      {bill.requestOf.map((requestOf, index) => (
                        <span key={index}> {requestOf.lawEntity && (requestOf.lawEntity.lastName || requestOf.lawEntity.firstName) ? `${requestOf.lawEntity.lastName || ''}${requestOf.lawEntity.lastName && requestOf.lawEntity.firstName ? ', ' : ''}${requestOf.lawEntity.firstName || ''}` : ''} </span>
                      ))}
                    </td>
                  </tr>
                )}
                <tr>
                  <td scope="row">Fiscal Note</td>
                  <td>
                    {bill.hasFiscalNote ? <a href="#" className="text-decoration-underline" onClick={(e) => { e.preventDefault(); onClickOpenTab('Fiscal') }}>Yes</a> : "No"}
                  </td>
                </tr>
                {bill.fiscalNote === 'Y' && (
                  <tr>
                    <td scope="row">Fiscal Analyst</td>
                    <td>&nbsp;</td>
                  </tr>
                )}
                <tr>
                  <td scope="row">Preintroduction Required</td>
                  <td>
                    {bill.preintroductionRequired === "Y" ? "Yes" : "No"}
                  </td>
                </tr>
                <tr>
                  <td scope="row">Category</td>
                  <td>
                    {bill.deadlineCategory?.name ? bill.deadlineCategory.name : ""}
                  </td>
                </tr>
                <tr>
                  <td scope="row">Subject(s)</td>
                  <td>
                    {bill.subjects.map((subject, index) => (
                      <span key={index}>{subject.subject.description + ", "}</span>
                    ))}
                  </td>
                </tr>
                <tr>
                  <td scope="row">Transmittal Date</td>
                  <td>{bill.deadlineCategory?.transmittalDate ? convertISOToReadableTimeFormat(bill.deadlineCategory.transmittalDate) : ""}</td>
                </tr>
                <tr>
                  <td scope="row">Return Date</td>
                  <td>{bill.deadlineCategory?.returnDate ? convertISOToReadableTimeFormat(bill.deadlineCategory.returnDate) : ""}</td>
                </tr>
                <tr>
                  <td colSpan="2">
                    <table className="table table-bordered mt-2 mb-0">
                      <thead className="table-light">
                        <tr>
                          <th scope="col">Sections Affected</th>
                          <th scope="col">Effective Date</th>
                          <th scope="col">Qualifier</th>
                        </tr>
                      </thead>
                      <tbody>
                        {
                          billSection?.map((item) => {
                            return <tr>
                              <td>{item.billSections}</td>
                              <td>{convertTimestamp(item.effectiveDate)}</td>
                              <td>{convertTimestamp(item.effectiveDateQualifier)}</td>
                            </tr>
                          })
                        }
                      </tbody>
                    </table>
                  </td>
                </tr>
              </MDBTableBody>
            </MDBTable>
          </MDBCol>
        </MDBRow>
      </MDBContainer>
    </MDBTabsPane>
  );
};

export default Summary;