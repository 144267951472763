import React, {useCallback, useEffect, useMemo, useState} from 'react';
import {format, parseISO} from 'date-fns';
import {
    MDBDatatable,
    MDBDropdown,
    MDBDropdownItem,
    MDBDropdownMenu,
    MDBDropdownToggle,
    MDBTabsPane
} from 'mdb-react-ui-kit';
import {exportData} from '../../../helper/export';
import {dateHelpers} from '../../../helper/dates';
import axios from 'axios';

const FiscalLinks = ({ billFiscalNotes }) => {
    const fiscsalLinks = useMemo(() => ({
        columns: [{ label: 'Fiscal Documents', field: 'link' }],
        rows: billFiscalNotes.map(item => ({ link: <a href={item.pdfUrl}>{item.fileName}</a> }))
    }), [billFiscalNotes]);

    return (
        <MDBDatatable
            data={fiscsalLinks}
            pagination={false}
            bordered
            striped
            fixedHeader
            maxHeight='100vh'
        />
    );
};

const FiscalLinksRebuttals = ({ billFiscalNotesRebuttals }) => {
    const fiscsalLinks = useMemo(() => ({
        columns: [{ label: 'Fiscal Rebuttals', field: 'link' }],
        rows: billFiscalNotesRebuttals.map(item => ({ link: <a href={item.pdfUrl}>{item.fileName}</a> }))
    }), [billFiscalNotesRebuttals]);

    return (
        <MDBDatatable
            data={fiscsalLinks}
            pagination={false}
            bordered
            striped
            fixedHeader
            maxHeight='100vh'
        />
    );
};

const Fiscal = ({ active, bill, ordinal }) => {
    const params = new URLSearchParams();
    params.append('legislatureOrdinal', `${ordinal}`);
    params.append('sessionOrdinal', `${bill.id.sessionId}`);
    params.append('billType', `${bill.billType}`);
    params.append('billNumber', `${bill.billNumber}`);

    const tableHeading = [
        { label: 'Date', field: 'date', sort: true },
        { label: 'Status', field: 'status', sort: true }
    ];

    const convertISOToReadableTimeFormat = (date) => {
        return format(parseISO(date), "MM/dd/yy");
    };

    const transformStatusData = (statusArray) => {
        const transformedData = [];
        const uniqueStatuses = new Set();
        statusArray.forEach((statusItem) => {
            if (statusItem.actionType.description.toLowerCase().includes('fiscal')) {
                const statusDescription = statusItem.actionType.description;
                if (!uniqueStatuses.has(statusDescription)) {
                    uniqueStatuses.add(statusDescription);
                    transformedData.push({
                        date: convertISOToReadableTimeFormat(statusItem.date),
                        status: statusDescription
                    });
                }
            }
        });
        return transformedData;
    };

    const [originalData, setOriginalData] = useState([]);
    const [fiscalData, setFiscalData] = useState({
        columns: tableHeading,
        rows: []
    });


    const [billFiscalNotes, setBillFiscalNotes] = useState([]);
    const [billFiscalNotesRebuttals, setbillFiscalNotesRebuttals] = useState([]);

    useEffect(() => {
        const transformedData = transformStatusData(bill.billActions);
        setFiscalData({
            columns: tableHeading,
            rows: transformedData
        });
        setOriginalData(transformedData);
    }, [bill.billActions]);

    useEffect(() => {
        const fetchFiscalLinks = async () => {
            try {
                if(!ordinal || !bill.id.sessionId || !bill.id.billDraftNumber || !bill.billType || !bill.billNumber) throw new Error("bill details not available");
                const response = await axios.get(`${process.env.REACT_APP_LAWS_BACKEND_HOST}/docs/v1/documents/getBillFiscalNotes?${params.toString()}`);
                const billFiscalNotesWithPdfUrl = await Promise.all(
                    response.data.map(async (note) => {
                        try {
                            const pdfUrlResponse = await axios.post(`${process.env.REACT_APP_LAWS_BACKEND_HOST}/docs/v1/documents/shortPdfUrl?documentId=${note.id}`);
                            return { ...note, pdfUrl: pdfUrlResponse.data };
                        } catch (error) {
                            console.error(`Error fetching PDF URL for fiscal note ${note.id}: `, error);
                            return note;
                        }
                    })
                );
                setBillFiscalNotes(billFiscalNotesWithPdfUrl.reverse());
            } catch (error) {
                console.error('Error fetching bill fiscal notes: ', error);
            }
        };

        fetchFiscalLinks();
    }, [bill]);

    useEffect(() => {
        const fetchFiscalLinksRebuttals = async () => {
            try {
                if(!ordinal || !bill.id.sessionId || !bill.id.billDraftNumber || !bill.billType || !bill.billNumber) throw new Error("bill details not available");
                const response = await axios.get(`${process.env.REACT_APP_LAWS_BACKEND_HOST}/docs/v1/documents/getBillFiscalNotesRebuttals?${params.toString()}`);
                const billFiscalNotesWithPdfUrl = await Promise.all(
                    response.data.map(async (note) => {
                        try {
                            const pdfUrlResponse = await axios.post(`${process.env.REACT_APP_LAWS_BACKEND_HOST}/docs/v1/documents/shortPdfUrl?documentId=${note.id}`);
                            return { ...note, pdfUrl: pdfUrlResponse.data };
                        } catch (error) {
                            console.error(`Error fetching PDF URL for fiscal note ${note.id}: `, error);
                            return note;
                        }
                    })
                );
                setbillFiscalNotesRebuttals(billFiscalNotesWithPdfUrl.reverse());
            } catch (error) {
                console.error('Error fetching bill fiscal notes: ', error);
            }
        };

        fetchFiscalLinksRebuttals();
    }, [bill]);

    const dataForExport = fiscalData.rows;

    const handleCsvClick = () => {
        exportData(dataForExport, `Fiscal_details_${dateHelpers.getCurrentDate()}`, 'csv');
    };

    const handleSearchInputChange = useCallback((event) => {
        const keyword = event.target.value.toLowerCase();
        const filteredRowData = originalData.filter(row => row.status.toLowerCase().includes(keyword));
        setFiscalData(prevState => ({
            ...prevState,
            rows: filteredRowData
        }));
    }, [originalData]);

    return (
        <MDBTabsPane show={active}>
            <div className="accordion-body">
                <div className='row py-3 g-0'>
                    <div className='col-lg align-items-center text-center text-md-start mb-3 mb-md-0'>
                    </div>
                    <div className='col-sm align-items-center'>
                        <input style={{ width: '100%' }} onChange={handleSearchInputChange} className='form-control' placeholder='Search Fiscal...' type="text" />
                    </div>
                    <div className='col-auto mt-sm-0 mx-auto mt-3'>
                        <div className='d-flex justify-content-end p-0'>
                            <MDBDropdown>
                                <MDBDropdownToggle className='shadow-none ms-1'><i className="fa-light fa-cloud-arrow-down" aria-hidden="true"></i> Export</MDBDropdownToggle>
                                <MDBDropdownMenu>
                                    <MDBDropdownItem link onClick={handleCsvClick}>Excel/CSV</MDBDropdownItem>
                                    <MDBDropdownItem link onClick={(e) => { e.preventDefault(); exportData(dataForExport, `Fiscal_details_${dateHelpers.getCurrentDate()}`, 'pdf') }}>Print/PDF</MDBDropdownItem>
                                </MDBDropdownMenu>
                            </MDBDropdown>
                        </div>
                    </div>
                </div>
                <div className='row'>
                    <div className='col-6'>
                        <div id="table-fiscal_wrapper" className="dataTables_wrapper dt-bootstrap5 no-footer">
                            <MDBDatatable
                                data={fiscalData}
                                pagination={false}
                                bordered
                                striped
                                fixedHeader
                                maxHeight='100vh'
                            />
                        </div>
                    </div>
                    <div className='col-6'>
                        <FiscalLinks billFiscalNotes={billFiscalNotes} />
                        <p></p>
                        <FiscalLinksRebuttals  billFiscalNotesRebuttals={billFiscalNotesRebuttals}/>
                    </div>
                </div>
                <div className="mt-1" role="status" aria-live="polite">
                    <small>Showing 1 to {fiscalData.rows.length} of {fiscalData.rows.length} Items</small>
                </div>
            </div>
        </MDBTabsPane>
    );
};

export default Fiscal;