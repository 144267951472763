import React, { useState, useEffect } from 'react';
import {
    MDBNavbar,
    MDBContainer,
    MDBIcon,
    MDBNavbarNav,
    MDBNavbarItem,
    MDBNavbarLink,
    MDBNavbarToggler,
    MDBCollapse,
    MDBNavbarBrand,
} from 'mdb-react-ui-kit';

const PreHeader = () => {
    const [showNavRight, setShowNavRight] = useState(false);

    return (
        <MDBNavbar expand='lg' className='bg-color6 p-0'>
            <MDBContainer fluid>
                <MDBNavbarBrand href='#' tag="span" className='mb-0 text-white'>
                    <img width="50px" src="./logo.png"></img>
                    <span className="d-none d-lg-block">Montana Legislative Services</span>
                    <span className="d-lg-none">MT Legislative</span>
                </MDBNavbarBrand>
                {/* <MDBNavbarToggler
                    type='button'
                    data-target='#navbarRightAlignExample'
                    aria-controls='navbarRightAlignExample'
                    aria-expanded='false'
                    aria-label='Toggle navigation'
                    onClick={() => setShowNavRight(!showNavRight)}
                >

                    <MDBIcon icon='bars' fas />
                </MDBNavbarToggler>
                */}
            </MDBContainer>
        </MDBNavbar>
    );
}

export default PreHeader;
