import React, { useState, useEffect, useMemo } from 'react';
import { MDBDatatable, MDBDropdown, MDBDropdownItem, MDBDropdownMenu, MDBDropdownToggle, MDBTabsPane } from 'mdb-react-ui-kit';
import axios from 'axios';
import { exportData } from '../../../helper/export';

const tableHeading = [
    { label: 'Amendment', field: 'amendment', sort: true },
    { label: 'Condensed Version', field: 'condensed', sort: true },
];

const Amendments = ({ active, bill, ordinal }) => {
    const params = useMemo(() => {
        const paramsObj = new URLSearchParams();
        paramsObj.append('legislatureOrdinal', `${ordinal}`);
        paramsObj.append('sessionOrdinal', `${bill.id.sessionId}`);
        paramsObj.append('billType', `${bill.billType}`);
        paramsObj.append('billNumber', `${bill.billNumber}`);
        return paramsObj;
    }, [ordinal, bill.id.sessionId, bill.billType, bill.billNumber]);

    const [originalData, setOriginalData] = useState([]);
    const [amendmentsData, setAmendmentsData] = useState({
        columns: tableHeading,
        rows: []
    });
    const [dataForExport, setDataForExport] = useState();

    useEffect(() => {
        async function fetchData() {
            try {
                const response = await axios.get(`${process.env.REACT_APP_LAWS_BACKEND_HOST}/docs/v1/documents/getBillAmendments?${params.toString()}`);
                const amendmentsWithPdfUrl = await Promise.all(
                    response.data.map(async (note) => {
                        try {
                            const pdfUrlResponse = await axios.post(`${process.env.REACT_APP_LAWS_BACKEND_HOST}/docs/v1/documents/shortPdfUrl?documentId=${note.id}`);
                            return { ...note, pdfUrl: pdfUrlResponse.data };
                        } catch (error) {
                            console.error(`Error fetching PDF URL for fiscal note ${note.id}: `, error);
                            return note;
                        }
                    })
                );

                const condensedVersions = amendmentsWithPdfUrl.filter(item => item.fileName.includes('condensed'));

                const formattedRows = amendmentsWithPdfUrl
                    .filter(item => !item.fileName.includes('condensed'))
                    .map(item => {
                        const baseFileName = item.fileName.split('_')[0];
                        const condensed = condensedVersions.find(condensedItem => condensedItem.fileName.startsWith(baseFileName));
                        return {
                            amendment: <a target='_blank' rel='noopener noreferrer' href={item.pdfUrl}>{item.fileName}</a>,
                            condensed: condensed
                                ? <a target='_blank' rel='noopener noreferrer' href={condensed.pdfUrl}>Yes</a>
                                : 'No'
                        };
                    });

                    const formattedDataForExport = amendmentsWithPdfUrl
                    .filter(item => !item.fileName.includes('condensed'))
                    .map(item => {
                        const baseFileName = item.fileName.split('_')[0];
                        const condensed = condensedVersions.find(condensedItem => condensedItem.fileName.startsWith(baseFileName));
                        return {
                            amendment: item.fileName,
                            condensed: condensed ? "Yes" : "No"
                        };
                    });

                setOriginalData(formattedRows);
                setAmendmentsData({
                    columns: tableHeading,
                    rows: formattedRows
                });
                setDataForExport(formattedDataForExport);
            } catch (error) {
                console.error("Error fetching data: ", error);
                throw error;
            }
        }
        fetchData();
    }, [params]);

    const handleCsvClick = (e) => {
        e.preventDefault();
        exportData(dataForExport,'Amendments','csv');
    };

    const handlePdfClick = (e) => {
        e.preventDefault();
        exportData(dataForExport,'Amendments','pdf');
    };

    const handleSearchInputChange = (event) => {
        const keyword = event.target.value.toLowerCase();
        const filteredRowData = originalData.filter(row =>
            row.amendment.props.children.toLowerCase().includes(keyword) ||
            (typeof row.condensed === 'string' ? row.condensed.toLowerCase().includes(keyword) : row.condensed.props.children.toLowerCase().includes(keyword))
        );
        setAmendmentsData(prevState => ({
            ...prevState,
            rows: filteredRowData
        }));
    };

    return (
        <MDBTabsPane show={active}>
            <div className="accordion-body">
                <div className='row py-3 g-0'>
                    <div className='col-lg align-items-center text-center text-md-start mb-3 mb-md-0'>
                    </div>
                    <div className='col-sm align-items-center'>
                        <input
                            style={{ width: '100%' }}
                            onChange={handleSearchInputChange}
                            className='form-control'
                            placeholder='Search Amendments...'
                            type="text"
                        />
                    </div>
                    <div className='col-auto mt-sm-0 mx-auto mt-3'>
                        <div className='d-flex justify-content-end p-0'>
                            <MDBDropdown>
                                <MDBDropdownToggle className='shadow-none ms-1'>
                                    <i className="fa-light fa-cloud-arrow-down" aria-hidden="true"></i> Export
                                </MDBDropdownToggle>
                                <MDBDropdownMenu>
                                    <MDBDropdownItem link onClick={handleCsvClick}>Excel/CSV</MDBDropdownItem>
                                    <MDBDropdownItem link onClick={handlePdfClick}>Print/PDF</MDBDropdownItem>
                                </MDBDropdownMenu>
                            </MDBDropdown>
                        </div>
                    </div>
                </div>
                <div id="table-amendments_wrapper" className="dataTables_wrapper dt-bootstrap5 no-footer">
                    <MDBDatatable
                        data={amendmentsData}
                        pagination={false}
                        bordered
                        striped
                        noFoundMessage='No Amendments found'
                        fixedHeader
                        maxHeight='100vh'
                    />
                </div>
                <div className="mt-1" role="status" aria-live="polite">
                    <div className="dataTables_info" id="table-actions_info" role="status" aria-live="polite">
                        <small>Showing 1 to {amendmentsData.rows.length} of {amendmentsData.rows.length} Items</small>
                    </div>
                </div>
            </div>
        </MDBTabsPane>
    );
};

export default Amendments;