import React from 'react';
import { createRoot } from "react-dom/client";

import './index.scss';
// import 'mdb-react-ui-kit/dist/scss/mdb.pro.scss';
import App from './App';
import reportWebVitals from './reportWebVitals';

const container = document.getElementById("root");
const root = createRoot(container);
if (process.env.NODE_ENV !== 'production' || process.env.NODE_ENV !== 'test') {
    root.render(
        <React.StrictMode>
            <App />
        </React.StrictMode>
    );
} else {
    root.render(<App />);
}

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
