import React, { useState, useEffect } from 'react';
import { useNavigate, useLocation } from 'react-router-dom'; // Updated to useNavigate
import {
    MDBTabs,
    MDBTabsItem,
    MDBTabsLink,
    MDBTabsContent,
    MDBIcon
} from 'mdb-react-ui-kit';
import Summary from "./Tabs/Summary";
import BillText from './Tabs/BillText';
import DocumentsAndLinks from './Tabs/DocumentsAndLinks';
import Fiscal from './Tabs/Fiscal';
import Amendments from './Tabs/Amendments';
import Status from './Tabs/Status';

const tabMapping = {
    'Summary': 'sum',
    'BillText': 'bill',
    'Status': 'status',
    'Fiscal': 'fiscal',
    'Amendments': 'amend',
    'DocumentsAndLinks': 'docs'
};

const Tabs = ({billDetails, ordinal, defaultTab}) => {
    const [basicActive, setBasicActive] = useState('Summary');
    const navigate = useNavigate(); // Updated to useNavigate
    const location = useLocation();

    useEffect(() => {
        const mappedTab = Object.keys(tabMapping).find(key => tabMapping[key] === defaultTab.toLowerCase());
        if (mappedTab) {
            setBasicActive(mappedTab);
            updateQueryParam(tabMapping[mappedTab]);
        }
    }, [defaultTab]);

    const updateQueryParam = (tabValue) => {
        const params = new URLSearchParams(location.search);
        params.set('open_tab', tabValue);
        navigate({ search: params.toString() }, { replace: true });
    };

    const handleBasicClick = (value) => {
        if (value === basicActive) {
            return;
        }
        setBasicActive(value);
        updateQueryParam(tabMapping[value]);
    };

    return (
        <div>
            <MDBTabs>
                <MDBTabsItem>
                    <MDBTabsLink onClick={() => handleBasicClick('Summary')} active={basicActive === 'Summary'} >
                        <MDBIcon fas icon="clipboard-list" /> Summary
                    </MDBTabsLink>
                </MDBTabsItem>
                <MDBTabsItem>
                    <MDBTabsLink onClick={() => handleBasicClick('BillText')} active={basicActive === 'BillText'}>
                        <MDBIcon far icon="file-alt" /> Bill Text
                    </MDBTabsLink>
                </MDBTabsItem>
                <MDBTabsItem>
                    <MDBTabsLink onClick={() => handleBasicClick('Status')} active={basicActive === 'Status'}>
                        <MDBIcon far icon="check-circle" /> Status
                    </MDBTabsLink>
                </MDBTabsItem>
                <MDBTabsItem>
                    <MDBTabsLink onClick={() => handleBasicClick('Fiscal')} active={basicActive === 'Fiscal'}>
                        <MDBIcon fas icon="dollar-sign" /> Fiscal
                    </MDBTabsLink>
                </MDBTabsItem>
                <MDBTabsItem>
                    <MDBTabsLink onClick={() => handleBasicClick('Amendments')} active={basicActive === 'Amendments'}>
                        <MDBIcon fas icon="pen-nib" /> Amendments
                    </MDBTabsLink>
                </MDBTabsItem>
                <MDBTabsItem>
                    <MDBTabsLink onClick={() => handleBasicClick('DocumentsAndLinks')} active={basicActive === 'DocumentsAndLinks'}>
                        <MDBIcon fas icon="paperclip" /> Documents & Links
                    </MDBTabsLink>
                </MDBTabsItem>
            </MDBTabs>

            <MDBTabsContent className='bg-white p-3'>
                <Summary active={ basicActive === 'Summary'} bill={billDetails} onClickOpenTab={handleBasicClick} ordinal={ordinal}/>
                <BillText active={ basicActive === 'BillText'} bill={billDetails} ordinal={ordinal}/>
                <Status active={ basicActive === 'Status'} bill={billDetails} ordinal={ordinal}/>
                <Fiscal active={ basicActive === 'Fiscal'} bill={billDetails} ordinal={ordinal}/>
                <Amendments active={ basicActive === 'Amendments'} bill={billDetails} ordinal={ordinal} />
                <DocumentsAndLinks  active={ basicActive === 'DocumentsAndLinks'} bill={billDetails} ordinal={ordinal} />
            </MDBTabsContent>
        </div>
    );
};

export default Tabs;