import React, { useEffect, useState } from 'react';
import { MDBSelect, MDBSideNavCollapse, MDBSideNavItem, MDBSideNavLink, MDBSpinner } from 'mdb-react-ui-kit';
import axios from 'axios';

const Chapters = ({ chaptersCount, setChaptersCount, selectedChapters, setSelectedChapters, setOffset }) => {
    const [options, setOptions] = useState([]);
    const [collapse, setCollapse] = useState(false);
    const [isLoading, setIsLoading] = useState(true);

    useEffect(() => {
        if (chaptersCount) setIsLoading(false);
        else return;
        const chapters = Object.keys(chaptersCount);
        const mappedSubjects = chapters.map(item => ({
            value: item,
            text: item
        }));
        const removedDuplicatedSubjects = removeDuplicatesByCode(mappedSubjects);
        const options = [...removedDuplicatedSubjects];
        setOptions(options);
    }, [chaptersCount]);

    const removeDuplicatesByCode = (subjects) => {
        const uniqueCodes = new Set();
        return subjects.filter(item => !uniqueCodes.has(item.value) && uniqueCodes.add(item.value));
    };

    const handleChapterSelection = (value) => {
        if (value === '0' || !value) {
            setSelectedChapters([]);
        } else {
            const chapters = value.map( item => item.value) 
            setSelectedChapters(chapters);
        }
        setOffset(0);
    };

    return (
        <MDBSideNavItem>
            <MDBSideNavLink className='accordion-button' icon='angle-down' shouldBeExpanded={collapse} onClick={() => setCollapse(!collapse)}>
                Chapters
            </MDBSideNavLink>
            <MDBSideNavCollapse show={collapse}>
                {
                    isLoading ?
                        <span className='m-4'><MDBSpinner size='sm' role='status' tag='span' className='me-2' /> Loading...</span>
                        :
                        <li className="p-3">
                            <MDBSelect
                                multiple
                                data={options}
                                label="-- Select Chapters --"
                                id="chapterFilter"
                                search
                                clearBtn
                                onValueChange={(value) => { handleChapterSelection(value) }}
                                value={selectedChapters}
                            />
                        </li>
                }
            </MDBSideNavCollapse>
        </MDBSideNavItem>
    );
};

export default Chapters;
