import React, { useEffect, useState } from 'react';
import { MDBCheckbox, MDBInput, MDBRadio, MDBSelect, MDBSpinner } from 'mdb-react-ui-kit';
import axios from 'axios';

const Drafters = ({ sessionId, selectedDrafter, setSelectedDrafter, setOffset, draftersCount }) => {

    const [drafters, setDrafters] = useState([]);
    const [isLoading, setIsLoading] = useState(true);
    const [selectedOption, setSelectedOption] = useState("");

    useEffect(() => {
        const fetchData = async () => {
            try {
                let options = [];
                if (sessionId > 20231) {
                    const { data } = await axios.get(`${process.env.REACT_APP_LAWS_BACKEND_HOST}/legislators/v1/legislators`);
                    options = [{ value: "0", text:"-- Select Drafters --", disabled: true }, ...data.map(item => ({
                        value: item.id,
                        text: item.lastName + ", " + item.firstName
                    }))];
                } else {
                    const { data } = await axios.get(`${process.env.REACT_APP_LAWS_ARCHIVE_BACKEND_HOST}/v1/draftersBySession?sessionId=${sessionId}`);
                    options = [{ value: "0", text:"-- Select Drafters --", disabled: true }, ...data.map(item => ({
                        value: item.entityIdSequence,
                        text: item.lastName + ", " + item.firstName
                    }))];
                }
                setDrafters(options);
                setIsLoading(false);
            } catch (error) {
                console.error('Error fetching data: ', error);
            }
        };
        setIsLoading(true)
        fetchData();
    }, [sessionId]);

    useEffect(() => {
        if(!selectedDrafter){
            setSelectedOption("0")
        }
    }, [selectedDrafter]);

    const handleDraftersSelect = (value) => {
        if (value === "0") {
            setSelectedOption(value);
            setSelectedDrafter([]);
        } else {
            const selectedValue = parseInt(value, 10);
            setSelectedOption(value);
            if (sessionId > 20231) {
                if (value) {
                    setSelectedOption(value);
                    setSelectedDrafter([]);
                }else{
                    setSelectedDrafter([]);
                }
            } else {
                setSelectedDrafter(selectedValue);
            }
        }
        setOffset(0);
    };
    
    return (
        <div className="mb-3 mt-4">
            {isLoading ? <p><MDBSpinner size='sm' role='status' tag='span' className='me-2' /> Loading...</p> :
                <MDBSelect 
                    data={drafters}  
                    id="drafterFilter" 
                    search
                    clearBtn
                    value={selectedOption}
                    onValueChange={({ value }) => { handleDraftersSelect(value) }}
                />
            }
        </div>
    );
}

export default Drafters;
