import React, { useEffect, useState } from 'react';
import { MDBTabsPane, MDBRow, MDBCol, MDBTable, MDBTableBody, MDBContainer } from "mdb-react-ui-kit";
import './css/summary.css';
import { format, parseISO } from 'date-fns';
import ArrowProgressBar from '../ArrowProgressBar/ArrowProgressBar';
import axios from 'axios';

const convertISOToReadableTimeFormat = (date) => format(parseISO(date), "MM/dd/yy");

const convertTimestamp = (timestamp) => {
  try {
    if (!timestamp) return '';
    const datePart = timestamp.split(' ')[0];
    const [year, month, day] = datePart.split('-');
    const formattedMonth = month.padStart(2, '0');
    const formattedDay = day.padStart(2, '0');
    return `${formattedMonth}/${formattedDay}/${year}`;
  } catch (error) {
    return timestamp;
  }
};

const getMatchingNumber = (status, billType) => {
  const house = ['020', '040', '060', '090', '110', '180']; // HOUSE FLOW
  const senate = ['020', '090', '110', '040', '060', '180']; // SENATE FLOW
  let steps = 0;
  let dates = [];

  status.forEach((statusItem) => {
    if (statusItem.actionType.progressCategory) {
      const code = statusItem.actionType.progressCategory.code.toString();
      if (billType === 'H' && house.includes(code)) {
        const index = house.indexOf(code) + 1;
        steps = Math.max(steps, index);
        dates.push(convertISOToReadableTimeFormat(statusItem.date));
      } else if (billType === 'S' && senate.includes(code)) {
        const index = senate.indexOf(code) + 1;
        steps = Math.max(steps, index);
        dates.push(convertISOToReadableTimeFormat(statusItem.date));
      }
    }
  });

  return { steps, dates };
};

const Summary = ({ active, bill, onClickOpenTab, ordinal }) => {
  const params = new URLSearchParams();
  params.append('legislatureOrdinal', `${ordinal}`);
  params.append('sessionOrdinal', `${bill.id.sessionId}`);
  params.append('billType', `${bill.billType}`);
  params.append('billNumber', `${bill.billNumber}`);

  const primarySponsor = bill.primarySponsorBillRoles[0];
  const BillTypeChar = bill.billType ? bill.billType.charAt(0).toUpperCase() : 'H';
  const { steps, dates } = getMatchingNumber(bill.billActions, BillTypeChar);
  const billAmendmentsAvailableinBillActions = bill.billActions.find((item) => typeof item.amendmentFileName === "string");

  const [billType, setBillType] = useState(BillTypeChar);
  const [step, setStep] = useState(steps);
  const [legislatorProfilePic, setLegislatorProfilePic] = useState(false);
  const [legislatorProfileLink, setLegislatorProfileLink] = useState(false);
  const [isLoading, setIsLoading] = useState(true);
  const [billSection, setBillSection] = useState([]);
  const [chapterPdfUrl, setChapterPdfUrl] = useState();

  const sortDateWithAscendingStatus = bill.billActions.sort((a, b) => new Date(b.date) - new Date(a.date));


  useEffect(() => {
    async function fetchBillSection() {
      try {
        const response = await axios.get(`${process.env.REACT_APP_LAWS_ARCHIVE_BACKEND_HOST}/v1/billSections/${bill.id.sessionId}/${bill.id.billDraftNumber}`);
        setBillSection(response.data);
      } catch (error) {
        console.error("Error fetching bill section: ", error);
      }
    }

    async function fetchChapters() {
      if(params.legislatureOrdinal && params.sessionOrdinal && params.billType && params.billNumber){
        try {
          const response = await axios.get(`${process.env.REACT_APP_LAWS_BACKEND_HOST}/docs/v1/documents/getBillChapters?${params.toString()}`);
          return response.data;
        } catch (error) {
          console.error("Error fetching chapters: ", error);
        }
      }
    }

    function padNumber(number, prefix, suffix, length) {
      return `${prefix}${number.toString().padStart(length, '0')}.${suffix}`;
    }

    async function fetchSingleChapter() {
        try {
          let archiveChapterDoc = padNumber(bill.sessionLawChapterNumber ?  bill.sessionLawChapterNumber.toString() : "", "CH", "pdf",4);
          const response = await axios.get(`${process.env.REACT_APP_LAWS_BACKEND_HOST}/docs/v1/documents/findByName?documentName=${archiveChapterDoc}`);
          return response.data;
        } catch (error) {
          console.error("Error fetching chapters: ", error);
        }
    }

    async function fetchChapterPdfUrl(chapterId) {
      try {
        const response = await axios.post(`${process.env.REACT_APP_LAWS_BACKEND_HOST}/docs/v1/documents/shortPdfUrl?documentId=${chapterId}`);
        setChapterPdfUrl(response.data);
      } catch (error) {
        console.error("Error fetching chapter PDF URL: ", error);
      }
    }

    async function fetchAll() {
      setIsLoading(true);
      try {
        await fetchBillSection();
        const chapterPdfDoc = await fetchSingleChapter();
        if (chapterPdfDoc) {
          await fetchChapterPdfUrl(chapterPdfDoc[0].id);
        }
      } catch (error) {
        console.error("Error fetching all data: ", error);
      } finally {
        setIsLoading(false);
      }
    }
    fetchAll();
  }, []);

  return (
    <MDBTabsPane show={active}>
      <MDBContainer fluid>
        <MDBRow className='g-2'>
          <MDBCol md="9" className="ps-0">
            <MDBTable className="billTable">
              <MDBTableBody>
                <tr>
                  <td scope="row" width="225">Draft #</td>
                  <td>{bill.id.billDraftNumber == null ? "No Draft number" : bill.id.billDraftNumber}</td>
                </tr>
                <tr>
                  <td scope="row">Drafter</td>
                  <td>
                    {bill.drafters.length > 0 ? bill.drafters.map((drafter, index) => (
                      <span key={index}>{drafter.lawEntity?.lastName}, {drafter.lawEntity?.firstName}</span>
                    )) : ""}
                  </td>
                </tr>
                {bill.requesters.length > 0 && (
                  <tr>
                    <td scope="row">Requester</td>
                    <td>
                    {bill.requesters.map((requester, index) => (
                      <span key={index}>
                        {requester.lawEntity && 
                        (requester.lawEntity.lastName || requester.lawEntity.firstName) ? 
                          `${requester.lawEntity.lastName || ''}${requester.lawEntity.lastName && requester.lawEntity.firstName ? ', ' : ''}${requester.lawEntity.firstName || ''}` 
                          : ''}
                      </span>
                    ))}
                    </td>
                  </tr>
                )}
                {bill.requestOf.length > 0 && (
                  <tr>
                    <td scope="row">By Request Of</td>
                    <td>
                    {bill.requestOf.map((requestOf, index) => (
                        <span key={index}> {requestOf.lawEntity && (requestOf.lawEntity.lastName || requestOf.lawEntity.firstName) ? `${requestOf.lawEntity.lastName || ''}${requestOf.lawEntity.lastName && requestOf.lawEntity.firstName ? ', ' : ''}${requestOf.lawEntity.firstName || ''}` : ''} </span>
                      ))}
                    </td>
                  </tr>
                )} 
                {bill.fiscalNote === 'Y' && (
                  <tr>
                    <td scope="row">Fiscal Analyst</td>
                    <td>&nbsp;</td>
                  </tr>
                )}
                <tr>
                  <td scope="row">Preintroduction Required</td>
                  <td>
                    {bill.preintroductionRequired === "Y" ? "Yes" : "No"}
                  </td>
                </tr>
                <tr>
                  <td scope="row">Category</td>
                  <td>
                    {bill.deadlineCategory?.name ? bill.deadlineCategory.name : ""}
                  </td>
                </tr>
                <tr>
                  <td scope="row">Subject(s)</td>
                  <td>
                    {bill.subjects.map((subject, index) => (
                      <span key={index}>{subject.subject.description + ", "}</span>
                    ))}
                  </td>
                </tr>
              </MDBTableBody>
            </MDBTable>
          </MDBCol>
        </MDBRow>
      </MDBContainer>
    </MDBTabsPane>
  );
};

export default Summary;