import React, { useEffect, useState } from 'react';
import { MDBSelect, MDBSideNavCollapse, MDBSideNavItem, MDBSideNavLink, MDBSpinner } from 'mdb-react-ui-kit';
import axios from 'axios';

const Legislator = ({ sessionId, selectedLegislator, setSelectedLegislator, setOffset, legislatureId }) => {
    const [legislators, setLegislators] = useState([]);
    const [collapse, setCollapse] = useState(false);
    const [selectedOption, setSelectedOption] = useState("");
    const [isLoading, setIsLoading] = useState(true);

    const fetchData = async () => {
        try {
            let options;
            if (sessionId <= 20231) {
                const { data } = await axios.get(`${process.env.REACT_APP_LAWS_ARCHIVE_BACKEND_HOST}/v1/legislators/findBySession?sessionId=${sessionId}&limit=9999`);
                options = [{value: "0", text:"-- Select Legislator --", disabled: true}, ...data.content.map(item => ({
                    value: item.lawEntity.entityIdSequence,
                    text: `${item.lawEntity.lastName}, ${item.lawEntity.firstName}`
                }))];
            } else {
                const { data } = await axios.post(`${process.env.REACT_APP_LAWS_BACKEND_HOST}/legislators/v1/legislators/search`, { legislatureIds: [legislatureId] });
                options = [{ value: "0", text:"-- Select Legislator --", disabled: true }, ...data.content.map(item => ({
                    value: item.id,
                    text: `${item.firstName}, ${item.lastName}`
                }))];
            }

            options.sort((a, b) => {
                const lastNameA = a.text.split(', ')[0].trim(); 
                const lastNameB = b.text.split(', ')[0].trim();
                return lastNameA.localeCompare(lastNameB);
            });
            setLegislators(options);
            setIsLoading(false);
        } catch (error) {
            console.error('Error fetching data: ', error);
            setIsLoading(false);
        }
    };

    useEffect(() => {
        setIsLoading(true);
        fetchData();
    }, [sessionId]);

    useEffect( () => {
        if(!selectedLegislator){
            setSelectedOption("0")
        }
    });

    const handleLegislatorSelect = (value) => {
        if(value == "0"){
            setSelectedOption("0");
            setSelectedLegislator("");
        }else{
            setSelectedOption(value);
            setSelectedLegislator(value);
        }
        setOffset(0);
    }

    return (
        <MDBSideNavItem>
            <MDBSideNavLink className='accordion-button' icon='angle-down' shouldBeExpanded={collapse} onClick={() => setCollapse(!collapse)}>
                Legislator
            </MDBSideNavLink>
            <MDBSideNavCollapse show={collapse}>
                <li className='p-3'>
                    {isLoading ? <span className='mx-4'><MDBSpinner size='sm' role='status' tag='span' className='me-2' /> Loading...</span> :
                        <MDBSelect
                            className='legmt-checkbox'
                            clearBtn
                            onValueChange={({ value }) => { handleLegislatorSelect(value) }}
                            data={legislators}
                            value={selectedOption}
                            id="legislatorsFilter"
                            search
                        />
                    }
                </li>
            </MDBSideNavCollapse>
        </MDBSideNavItem>
    );
};

export default Legislator;